import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    constructor(
        private translateService: TranslateService,
        private cmsService: LozztoysCmsService,
        private modalService: NgbModal,  
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {

    }
    currentLanguage: string = 'sq';
    footerItems: any;
    cmsUrl = environment.backendUrl;

    ngOnInit() {
        this.translateService.onLangChange.subscribe((res) => {
            this.currentLanguage = res.lang;
            this.getFooterItems();
        });
    }

    scrollToTop() {
        if(isPlatformBrowser(this.platformId))
        {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    selectedLanguage = 'English';
    languages: string[] = ['English', 'Albanian', 'Macedonian'];

    changeLanguage1(event: Event, language: string): void {
        event.preventDefault(); // Prevents the default anchor behavior
        this.selectedLanguage = language;
    }

    getFooterItems(){
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getFooter(populateAndLocale).subscribe({
            next: (res) => {
                this.footerItems = res.data.attributes;
            },
            error: (err) => {
                console.error(err);
            }
        })
    }

    handleClick(link: string) {
        if (!link) return;
    
        if (this.isEmail(link)) {
          window.location.href = `mailto:${link}`;
        } else if (this.isPhoneNumber(link)) {
          window.location.href = `tel:${link}`;
        } else {
          this.redirectTo(link);
        }
      }
    
      isEmail(link: string): boolean {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(link);
      }
    
      isPhoneNumber(link: string): boolean {
        const phonePattern = /^\+?[0-9\s\-()]+$/;
        return phonePattern.test(link);
      }
    
      redirectTo(link: string) {
        if (link) {
          this.router.navigate([link]);
        }
      }

      open(url: string){
        window.open(url, '_blank');
      }

      sendTo(){
        console.log('send to emnail')
      }

}
