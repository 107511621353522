import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Product } from '../../modules/interfaces';
import { CookieService } from 'ngx-cookie-service';
import { LozztoysCmsService } from '../lozztoysCmsServices/lozztoys-cms.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { ConomeECommerceService } from '../conomeServices/conome-e-commerce.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private cartProductsSubject = new BehaviorSubject<Product[]>([]);
    cartProducts$: Observable<Product[]> = this.cartProductsSubject.asObservable();
    private totalPrice: number = 0;
    private numberOfProducts: number = 0;
    private buyNowProductSubject = new BehaviorSubject<any | null>(null);
    buyNowProduct$ = this.buyNowProductSubject.asObservable();
  
    constructor(
        private cookieService: CookieService,
        private cmsService: LozztoysCmsService,
        private translateService: TranslateService,
        private conomeService: ConomeECommerceService,
        private http:HttpClient
    ){

    }

    getCoupons() {
        return this.http.get<any>(`${environment.backendUrl}/api/coupons?populate[products]=*&populate[categories]=*`);
    }

    getAppliedCoupon(couponCode: string) {
        return this.http.get<any>(`${environment.backendUrl}/api/coupons?filters[CouponCode][$eq]=${couponCode}&populate[products]=*&populate[categories]=*`);
    }

    setBuyNowProduct(product: any) {
        this.buyNowProductSubject.next(product);
    }

    clearBuyNowProduct() {
        this.buyNowProductSubject.next(null);
    }
    
    updateCartProducts(products: any[]) {
        const updatedProducts = products.map(product => {
            const matchingDetails = product.productDetails?.filter((detail: any) => detail.id === product.productDetailId) || [];
            return {
                ...product,
                productDetails: matchingDetails
            };
        });
        updatedProducts.sort((a: any, b: any) => {
            if (a.id !== b.id) {
                return b.id - a.id;
            }
            return a.productDetailId - b.productDetailId;
        });

        this.cartProductsSubject.next(updatedProducts);
    }
  
    calculateTotalPrice(cartProducts: any[]): number {
        const total = cartProducts.reduce((total, product) => total + (product.productDetails[0]?.sellingPriceWithVat * product.quantity), 0).toFixed(2);
        this.setTotal(total);
        return total
    }

    setTotal(total: number){
        this.totalPrice = total;
    }

    getTotal(){
        return this.totalPrice;
    }

    setNumberOfProducts(total: number){
        this.numberOfProducts = total;
    }

    getNumberOfProducts(total: any){
        return total.length;
    }

    addToCart(product: any, productDetail: any, userId: string, quantity: number = 1) {
        if (userId) {
          const newCartItem = {
            productId: product.id,
            productDetailId: productDetail.id,
            quantity: quantity, 
            userId: userId
          };
      
          this.cmsService.getCartByUser(userId).subscribe({
            next: (response: any) => {
              const existingCart = response?.data || [];
              const orderMap: { [key: string]: any } = {};
      
              existingCart.forEach((item: any) => {
                const productId = item.attributes.productId;
                const productDetailId = item.attributes.productDetailId;
                const key = `${productId}_${productDetailId}`;
                orderMap[key] = {
                  id: item.id,
                  quantity: item.attributes.quantity,
                  productId: productId,
                  productDetailId: productDetailId
                };
              });
      
              const newCartKey = `${newCartItem.productId}_${newCartItem.productDetailId}`;
              if (orderMap[newCartKey]) {
                const existingItem = orderMap[newCartKey];
                const updatedQuantity = existingItem.quantity + quantity;
      
                this.cmsService.updateCartProduct({
                  id: existingItem.id,
                  quantity: updatedQuantity
                }).subscribe(() => {
                  this.handleFetchedProducts(Object.values(orderMap));
                });
      
                orderMap[newCartKey].quantity = updatedQuantity;
      
              } else {
                this.cmsService.setCartList(newCartItem).subscribe(() => {
                  orderMap[newCartKey] = {
                    id: null,
                    quantity: quantity,
                    productId: newCartItem.productId,
                    productDetailId: newCartItem.productDetailId,
                  };
      
                  this.handleFetchedProducts(Object.values(orderMap));
                });
              }
            }
          });
      
        } else {
          let productsArray: any = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
          const indexProduct = productsArray.findIndex((p: any) => p.productId === product.id && p.productDetailId === productDetail.id);
      
          if (indexProduct === -1) {
            productsArray.push({
              productId: product.id,
              productDetailId: productDetail.id,
              quantity: quantity,
              image: product.image,
            });
          } else {
            productsArray[indexProduct].quantity += quantity;
          }
          this.cookieService.set(LOCAL_STORAGE.PRODUCTS, JSON.stringify(productsArray), { path: '/' });
      
          this.handleFetchedProducts([...productsArray]);
        }
      }
    
    handleFetchedProducts(productsArray: any) {
        this.conomeService.products$.subscribe((fetchedProducts: any) => {
            if (fetchedProducts.length > 0) {
                // Merge product information with the existing cart
                const updatedProductsArray = productsArray.map((cartItem: any) => {
                    const matchingProduct = fetchedProducts.find((p: any) => p.id === cartItem.productId);
                    return matchingProduct ? { ...cartItem, ...matchingProduct } : cartItem;
                });
                this.updateCartProducts(updatedProductsArray);
                this.getNumberOfProducts(updatedProductsArray);
            }
        });
    }
    
    updateCartItemInCMS(product: any, userId: string) {
        this.cmsService.getCartByUser(userId).subscribe({
            next: (response: any) => {
                const existingCart = response?.data || [];
                const productIndex = existingCart.findIndex(
                    (cartItem: any) => cartItem.attributes.productId === product.id
                );
    
                if (productIndex !== -1) {
                    const cartItem = existingCart[productIndex];
                    this.cmsService.updateCartProduct({
                        id: cartItem.id,
                        quantity: product.quantity
                    }).subscribe(() => {
                    });
                }
            }
        });
    }

    updateCartInCookies(product: any) {
        const productsArray = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
        const indexProduct = productsArray.findIndex((p: any) => p.productId === product.id && p.productDetailId ===product.productDetailId );
    
        if (indexProduct !== -1) {
            productsArray[indexProduct].quantity = product.quantity;
            this.cookieService.set(LOCAL_STORAGE.PRODUCTS, JSON.stringify(productsArray), { path: '/' });
        }
    
        this.handleFetchedProducts(productsArray)
    }

    removeProductFromCookies(productId: number) {
        const productsArray = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
        const updatedProductsArray = productsArray.filter((p: any) => p.productId !== productId);
        this.cookieService.set(LOCAL_STORAGE.PRODUCTS, JSON.stringify(updatedProductsArray), { path: '/' });
    }

    fetchCartData(userId: string, productsFromCartIds: number[], productsFromConome: any[]): Observable<any> {
        if (productsFromConome && productsFromConome.length > 0) {
            return this.processCart(userId, productsFromConome, productsFromCartIds);
        }
        else {
            return of([])
        }
    }
  
    private processCart(userId: string, productsFromConome: any[], productsFromCartIds: number[]): Observable<any> {
        if (userId === '') {
        const productsFromCookies = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
    
        const cartProducts = productsFromConome
            .map((product: any) => {
            return product.productDetails
                .filter((detail: any) => {
                return productsFromCookies.some((cookieItem: any) => 
                    cookieItem.productId === product.id && 
                    cookieItem.productDetailId === detail.id
                );
                })
                .map((detail: any) => {
                const cartItem = productsFromCookies.find((item: any) => 
                    item.productId === product.id && 
                    item.productDetailId === detail.id
                );
                return {
                    ...product,
                    productDetailId: detail.id,
                    quantity: cartItem ? cartItem.quantity : 1
                };
                });
            })
            .reduce((acc: any[], val: any[]) => acc.concat(val), []);
            const filteredCartProducts = cartProducts.map((product: any) => {
            const filteredDetails = product.productDetails.filter((detail: any) => detail.id === product.productDetailId);
            return {
            ...product,
            productDetails: filteredDetails
            };
        });
        return of(filteredCartProducts);
        } else {
        return this.cmsService.getCartByUser(userId).pipe(
            switchMap((response: any) => {
            const cartItems = response?.data || [];
    
            const cartProducts = productsFromConome
                .map((product: any) => {
                return product.productDetails
                    .filter((detail: any) => {
                    return cartItems.some((item: any) =>
                        parseInt(item.attributes.productId) === product.id &&
                        parseInt(item.attributes.productDetailId) === detail.id
                    );
                    })
                    .map((detail: any) => {
                    const cartItem = cartItems.find((item: any) =>
                        parseInt(item.attributes.productId) === product.id &&
                        parseInt(item.attributes.productDetailId) === detail.id
                    );
                    return {
                        ...product,
                        productDetailId: detail.id,
                        quantity: cartItem ? cartItem.attributes.quantity : 1
                    };
                    });
                })
                .reduce((acc: any[], val: any[]) => acc.concat(val), []);
    
                const filteredCartProducts = cartProducts.map((product: any) => {
                const filteredDetails = product.productDetails.filter((detail: any) => detail.id === product.productDetailId);
                return {
                ...product,
                productDetails: filteredDetails
                };
            });
    
            return of(filteredCartProducts);
            })
        );
        }
    }

}
