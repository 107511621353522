import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppModule } from 'src/app/app.module';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-product-details',
  // standalone: true,
  // imports: [AppModule],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent {

  productsFromConome: any[] = [];
  environment: any;
  userId: string = '';
  productDetail: any;
  productId: number = -1
  currentProductDetail: any;
  currentLanguage: string = 'sq';
  breadCrumbItems!: any;
  serviceData: any[] = [];
  allImagesProductDetaill: any;
  value: number = 1; 
  currentRelatedProduct: any;
  currentRelatedProductDetail: any;
  showSuccessMessage: boolean = false;
  successMessage: string = '';
  showWarningMessage: boolean = false;
  wishListData: any[] = [];
  relatedItems: any;
  relatedHeader: any

  constructor(
    private translateService: TranslateService,
    public cartService: CartService,
    private cookieService: CookieService,
    private conomeService: ConomeECommerceService,
    private cmsService: LozztoysCmsService,
    private userProfileService: UserProfileService,
    private sharedService: DataSharedService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.environment = environment
  }

  ngOnInit() {
    this.userProfileService.userId$.subscribe(userId => {
      this.userId = userId;
    });

    combineLatest([
      this.activatedRoute.params,
      this.conomeService.products$
    ]).subscribe(([params, products]) => {
      this.productsFromConome = products;
      if (this.productsFromConome.length > 0) {
        const productId = params['id'];
        if (productId) {
          this.productId = productId;
          this.getProduct()
        }
      }
    });
    this.getDataFromCMS();
    this.getFromWishList();
    this.translateBreadcrumbItems();

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLanguage = res.lang;
      this.translateBreadcrumbItems();
      this.getDataFromCMS();
    });

  }

  getDataFromCMS() {
    const locale = this.currentLanguage;
    const populate = 'deep';
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;
    this.cmsService.getProductDetail(populateAndLocale).subscribe({
      next: (res) => {
        this.relatedHeader = res.data.attributes.relatedHeader
        this.serviceData = res.data.attributes.serviceSection;
      }
    })
  }

  private async translateBreadcrumbItems() {
    const homeLabel = await this.translateService.get("checkout.PRODUCT").toPromise();
    const firstLabel = await this.translateService.get("menu.PRODUCT_DETAILS").toPromise();

    this.breadCrumbItems = [
      { label: homeLabel, link: '/products' },
      { label: firstLabel, link: '/product-details' },
    ];
  }

  increaseValue() {
    if (this.value < this.currentProductDetail?.qt) {
      this.value++;
    }
  }

  decreaseValue() {
    if (this.value > 0) {
      this.value--;
    }
  }

  limitNumberLength(event: any, maxLength: number) {
    const input = event.target;      
    if (input.value > maxLength) input.value = maxLength
  }

  getProduct() {

    if (this.productsFromConome.length > 0) {
      this.productDetail = this.productsFromConome.find(prod => prod.id == this.productId)
      this.currentProductDetail = this.productDetail.productDetails[0];
      this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages

      const productId = this.productDetail?.id;
      const productJustForYouCategoryId = this.productDetail?.categoryID;

      let filteredProducts = this.productsFromConome.filter((item: any) =>
        item.categoryID === productJustForYouCategoryId && item.id !== productId
      );

      const getRandomSample = (array: any[], size: number) => {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, size);
      };

      if (filteredProducts.length > 4) {
        this.relatedItems = getRandomSample(filteredProducts, 4);
      }
      else if (filteredProducts.length < 4) {
        const additionalProducts = this.productsFromConome.filter(
          (item: any) => !filteredProducts.some((fp: any) => fp.id === item.id) && item.id !== productId
        );
        const additionalSample = getRandomSample(additionalProducts, 4 - filteredProducts.length);
        this.relatedItems = [...filteredProducts, ...additionalSample];
      }
      else {
        this.relatedItems = filteredProducts;
      }
    }
  }

  selectProductDetail(detail: any) {
    if (detail.qt > 0) {
      this.currentProductDetail = detail;
      this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages


    } else {
      const nextAvailableDetail = this.productDetail.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentProductDetail = nextAvailableDetail;
        this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages

      } else {
        return;
      }
    }
  }

  selectRelatedProduct(detail: any) {
    if (detail.qt > 0) {
      this.currentRelatedProductDetail = detail;
    } else {
      const nextAvailableDetail = this.currentRelatedProductDetail?.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentRelatedProductDetail = nextAvailableDetail;
      } else {
        return;
      }
    }
  }

  selectProductDetailForWishlist(detail: any) {
    if (detail.qt > 0) {
      this.currentProductDetail = detail;
    } else {
      const nextAvailableDetail = this.productDetail.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentProductDetail = nextAvailableDetail;
      } else {
        return;
      }
    }
  }

  filterImg(e: any, image: any) {
    document.querySelectorAll('.product-gallery-thumblist a').forEach(element => {
      element.classList.remove('active')
    });
    const img: HTMLImageElement = (document.querySelector('#first img') as HTMLImageElement);
    img.src = image;
    e.target.closest('a').classList.add('active');
  }

  addToCart(product: any, currentProductDetail: any) {
    this.showSuccessMessage = true;
    this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART")
    this.cartService.addToCart(product, currentProductDetail, this.userId, this.value)
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 2000);

  }

  buyNow(product: any, productDetail: any) {
    const newCartItem = {
      ...product,
      productDetails: [productDetail],
      quantity: this.value,
    };
    this.cartService.setBuyNowProduct(newCartItem);
    this.router.navigate(['/checkout'])
  }
  
  addToWishList(product: any, currentProductDetail: any) {
    let productsArray: any[] = [];
    this.showSuccessMessage = false;
    this.showWarningMessage = false;
    const productDetailId = currentProductDetail.id;
    if(currentProductDetail.qt > 0){      
      if (this.userId === '') {
        // Get the stored wishlist from cookies
        const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
        if (storedWishlist) {
          productsArray = JSON.parse(storedWishlist);
          if (!Array.isArray(productsArray)) {
            productsArray = [];
          }
        }
  
        const productExists = productsArray.some(
          item => item.productId === product.id && item.productDetailId === productDetailId
        );
  
        if (productExists) {
          this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
          this.showWarningMessage = true;
        } else {
          // Add the product to the wishlist array
          productsArray.push({ productId: product.id, productDetailId: productDetailId, image: product.image });
  
          // Set the wishlist cookie with path '/'
          this.cookieService.set(
            LOCAL_STORAGE.WISH_LIST,
            JSON.stringify(productsArray),
            { path: '/' } // Ensure the cookie is stored at root path
          );
          this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
          this.showSuccessMessage = true;
        }
      } else {
        const wishlistData = {
          productId: product.id,
          productDetailId: productDetailId,
          userId: this.userId,
          image: product.image
        };
  
        const productExists = this.wishListData.some(
          (item: any) => item.productId === product.id && item.productDetailId === productDetailId
        );
  
        if (productExists) {
          this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
          this.showWarningMessage = true;
        } else {
          this.wishListData = [...this.wishListData, wishlistData];
          productsArray.push(wishlistData);
  
          this.cmsService.setWishList(wishlistData).subscribe(
            response => {
            }
          );
  
          this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
          this.showSuccessMessage = true;
        }
      }
      this.getFromWishList();
      setTimeout(() => {
        this.showSuccessMessage = false;
        this.showWarningMessage = false;
      }, 2000);
    }
  }


  getFromWishList() {
    if (this.userId === '') {
      const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
      this.wishListData = wishListCmsData ? JSON.parse(wishListCmsData) : []
    } else {
      this.cmsService.getWishList(this.userId).subscribe(res => {
        this.wishListData = res.data
          .map((item: any) => {
            return {
              productId: item.attributes.productId,
              productDetailId: item.attributes.productDetailId,
              userId: item.attributes.userId
            };
          });
      });
    }
  }

  isInWishList(item: any): boolean {
    if (item) {
      return this.wishListData.some((wish: any) => wish.productId === item.id && wish.productDetailId === this.currentProductDetail?.id);
    } else {
      return false;
    }
  }

  isInWishListRelated(item: any): boolean {
    if (item) {
      return this.wishListData.some((wish: any) => wish.productId === item.id);
    } else {
      return false;
    }
  }

  productModal(productDetailModal: any, currentProduct: any) {
    this.currentRelatedProduct = currentProduct
    
    const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);
    if(currentProduct.productDetails[0].qt > 0){
      this.currentRelatedProductDetail = currentProduct.productDetails[0];
    } else if (availableDetail) {
      this.currentProductDetail = availableDetail;
      this.currentRelatedProductDetail = availableDetail
    } else {
      return;
    }
    this.modalService.open(productDetailModal, { size: 'xl', centered: true });
  }

  productDetails(id: any) {
    this.router.navigate(['product-details', id])
  }

  isOutOfStock(item: any): boolean {
    return item?.productDetails?.every((pd: any) => pd.qt <= 0);
  }

  truncateText(text: string, limit: number = 10): string {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) : text;
  }

  redirectTo(link: string) {
    this.router.navigate([link])
  }
}
