import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConomeECommerceService {

    apiUrl = environment.conomeUrl
    private productsSource = new BehaviorSubject<any[]>([]);
    products$ = this.productsSource.asObservable();
    private categoriesSource = new BehaviorSubject<any[]>([]);
    categories$ = this.categoriesSource.asObservable();

    constructor(private http: HttpClient) { }

    setProductsData(products: any){
        products = Array.isArray(products)
        ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : products;
        this.productsSource.next(products);
    }

    getProductsData(){
        return this.productsSource.getValue();
    }

    setCategoriesData(categories: any){
        categories = Array.isArray(categories)
        ? categories.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : categories;
        this.categoriesSource.next(categories);        
    }

    getCategoriesData(){
        return this.categoriesSource.getValue();
    }

    getCompanyInfo(): Observable<any>{
        const token = environment.company_token;
        const headers = new HttpHeaders().set('Authorization', token);

        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/CompanyInfo`, { headers })
    }


    getCategories(companyId: number): Observable<any>{
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/categories?companyId=${companyId}`)
    }

    getProductCategories(companyId: number): Observable<any>{
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/productcategories?companyId=${companyId}`);
    }


    registerSale(sale:any):Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.post<any>(`${this.apiUrl}/api/EcommerceApi/ResgisterSale`, sale, { headers })
    }

    createOrder(order:any):Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<any>(`${this.apiUrl}/api/OrdersApi/CreateOrder`, order, { headers })
    }

    checkOut(amount: number, orderId: number, baseUrl: string) {
        return this.http.get(`${this.apiUrl}/api/OrdersApi/Checkout`,{ params: { amount: amount, orderId: orderId, baseUrlUI: baseUrl } });
    }

    sendOrderEmail(orderId: number, userId: number) {
        userId = !userId ? -1 : userId;
        const data = { orderId, userId};
        return this.http.post(`${this.apiUrl}/api/OrdersApi/sendOrderConfirmationEmail`, data);
    }

    getOrderById(id:number): Observable<any>{
        return this.http.get(`${this.apiUrl}/api/OrdersApi/GetOrder/${id}`);
    }

    getAllProducts(companyId: number, page: number = 1, pageSize: number = 20) : Observable<any>{
        let params = new HttpParams()
        .set('companyId', companyId)
        .set("page", page)
        .set('pageSize', pageSize);
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/AllProducts`, { params });
    }

    getProductsByCategoryId(companyId: number, categoryId: number, page: number = 1, pageSize: number = 20): Observable<any>{
        let params = new HttpParams()
        .set('companyId', companyId)
        .set('categoryId', categoryId)
        .set('page', page)
        .set('pageSize', pageSize);

        return this.http.get(`${this.apiUrl}/api/EcommerceApi/ProductsByCategoryId`, { params });
    }
}
