import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { LoginResponse, Product } from 'src/app/modules/interfaces';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgetPasswordModalComponent } from 'src/app/components/forget-password-modal/forget-password-modal.component';
import Swal from 'sweetalert2';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';


@Component({
    selector: 'app-login',
    standalone: false,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {

    signInForm = new FormGroup({

        Username : new FormControl(''),
        password : new FormControl(''),
    });

    labelError!: string;
    labelInfo!: string;
    resetPassword = false;
    sendEmailResult = "";

  @Output() isUserLoggedInChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() userData: EventEmitter<any> = new EventEmitter<any>();



  constructor(
    private cookieService: CookieService,
    private cartService: CartService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserProfileService,
    private sharedService: DataSharedService,
    private conomeService: ConomeECommerceService
  ){
    
  }
  productsFromConome:any[] = []
  userId:string = ''
  
  getProducts() {
    this.cartService.fetchCartData(this.userId, [], this.productsFromConome).subscribe({
        next: (products: any[]) => {
            this.productsFromConome = products;
            this.cartService.updateCartProducts(products);
        }
    });
}
  login(data:any) {
      this.labelError = 'error';
      this.labelInfo = 'info';
      this.userService
          .login(data)
          .subscribe(
              (response) => {
                  const httpResponse: LoginResponse =
                    response as LoginResponse;
                  this.sharedService.setLoginStatus(true);
                  this.userData.emit(httpResponse.user);
                  this.cookieService.set(LOCAL_STORAGE.TOKEN, httpResponse.token);
                  this.cookieService.set("username", httpResponse.user.displayName);
                  this.cookieService.set('userID', httpResponse.id.toString());                    
                  this.userService.setUserId(httpResponse.id.toString());
                  this.userId = httpResponse.id.toString()
                  this.conomeService.products$.subscribe(products => {
                      this.productsFromConome = products;
          
                      if (this.productsFromConome.length > 0) {
                          this.getProducts();
                      }
                  });
                  this.router.navigate(['home']);
              },
              (error) => {
                  // Login failed, handle the error (e.g., display an error message)
                  Swal.fire({
                      icon: 'error',
                      title: 'Login Failed',
                      text: 'Invalid credentials. Please try again.',
                      confirmButtonText: 'OK',
                  });
                  this.labelError = 'login-page.ERROR-MESSAGE';
              }
          );
  }

  openForgotPasswordModal() {
      const modalRef = this.modalService.open(ForgetPasswordModalComponent);
      modalRef.result.then(
          (result) => {
          },
          (reason) => {
          }
      );
  }
}
