import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    
    breadCrumbItems!: any;
    contact: any[] = [];
    contactTitle = '';
    currentLanguage = 'sq';
    cmsUrl = environment.backendUrl;

    constructor(private cmsService: LozztoysCmsService, private translateService: TranslateService) {

    }


    ngOnInit(){
        this.getContactSection();
        this.translateBreadcrumbItems(); // Translate initially
        // Subscribe to language changes and update translations
        this.translateService.onLangChange.subscribe((res) => {
            this.currentLanguage = res.lang;
            this.getContactSection();
            this.translateBreadcrumbItems();
        });
    }

    //is used for banner data
    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.CONTACT").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/contact' },
        ];
    }

    getContactSection(){
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getContact(populateAndLocale).subscribe({
            next: (res) => {
                this.contactTitle = res.data.attributes.title
                this.contact = res.data.attributes.contact_info.map((item:any) => {
                    return {
                        title: item.Title,
                        subtitle: item.subtitle,
                        icon: this.cmsUrl + item.icon.data.attributes.url
                    }
                })
            },
            error: (err) => {
                console.error(err);
            }
        })
    }
}
