import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forget-password-modal',
    standalone: false,
    templateUrl: './forget-password-modal.component.html',
    styleUrl: './forget-password-modal.component.scss'
})
export class ForgetPasswordModalComponent {


    forgotPasswordForm = new FormGroup({

        Username : new FormControl(''),
    
    });

    constructor(private userService: UserProfileService, private modalService: NgbModal){

    }
  
    forgotPassword(username: any) {
        const data = { ...username };
  
        this.userService.forgotpassword(data).subscribe({
            next: (res) => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Reset password email is sent. Please check your mailbox.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            },
            error: (error) => {
                Swal.fire({
                    title: 'Error!',
                    text: 'An error occurred while sending the reset password email. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        });
    }

    closemodal() {
        this.modalService.dismissAll();
    }
}
