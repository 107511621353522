import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { timer } from 'rxjs';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { Product } from 'src/app/modules/interfaces';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';

@Component({
    selector: 'app-signup',
    standalone: false,
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent {
    signupForm: FormGroup;

    isFormValid = false;
    registrationSucces = false;
    errorMessage!: string;




    constructor(
    private formBuilder: FormBuilder,
    private cartService:CartService,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userService: UserProfileService,


    ){

        this.signupForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            lastname: new FormControl(''),
            email: ['', [Validators.required, Validators.email]],
            PlaceId : new FormControl(''),
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    this.passwordMatchValidator,
                ],
            ],
            confirmPassword: new FormControl(''),
            Address : new FormControl(''),
            AddressNumber : new FormControl(''),
            BankName : new FormControl(''),
            IBAN : new FormControl(''),
            SWIFT : new FormControl(''),
            BankAccount : new FormControl(''),
            phoneNumber: new FormControl('')
        });

        this.signupForm.valueChanges.subscribe((value) => {
            if (this.signupForm.valid) {
                this.isFormValid = true;
            } else {
                this.isFormValid = false;
            }
        });
    }


    onSubmit() {
        if(isPlatformBrowser(this.platformId)){

            const companyID = parseInt(this.cookieService.get("companyID"))
            localStorage.clear();
            const productsArray: Product[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || '[]');
            this.cartService.updateCartProducts(productsArray);
            if (this.signupForm.valid) {
                const signUpData = {
                    Name: this.signupForm.value.name,
                    /* Surname: this.signupForm.value.lastname, */
                    Email: this.signupForm.value.email,
                    Password: this.signupForm.value.password,
                    isEcommerce: true,
                    CompanyID: companyID
                    /* confirmPassword: this.signupForm.value.confirmPassword,
            Address: this.signupForm.value.Address,
            AddressNumber: this.signupForm.value.AddressNumber,
            PhoneNumber: this.signupForm.value.phoneNumber */
                };

                this.userService.register(signUpData).subscribe(
                    (response) => {
                        this.errorMessage = '';
                        this.signupForm.reset();
                        this.registrationSucces = true;
                        timer(3000).subscribe(() => {
                            this.registrationSucces = false;
                        });
                    },
                    (error) => {
                        if (error.status === 400) {
                            // Display an error message to the user
                            this.errorMessage = 'sign-up.ERROR-MESSAGE';
                        }
                    }
                );
            }
        }
    }

    get signupform() {
        return this.signupForm.controls;
    }




    passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.get('password')?.value;
        const confirmPassword = control.get('confirmPassword')?.value;
        const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]+$/;

        if (password !== confirmPassword) return { passwordMatch: true };
        if (!password) return null;

        if (password !== confirmPassword) return { passwordMatch: true };

        if (!password.match(passwordRegex)) return { invalidPassword: true };

        if (password !== confirmPassword) return { passwordMatch: true };

        return null;
    }
}
