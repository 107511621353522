<div class="container">
  <div class="row banner-container">
    <div class="col-12 col-md-2 categories">
      <ul class="categories-list list-unstyled">
        <li class="d-flex align-items-center mb-1 pointer" (click)="selectedCategory = -1; filterProductsByCategory()"
          [ngClass]="selectedCategory === -1 ? 'active' : ''">
          <p class="m-0">{{"questions.ALL" | translate}}</p>
    
        <li class="d-flex align-items-center mb-1 pointer" *ngFor="let category of topCategories"
        (click)="redirectToProducts('products', category)"
          [ngClass]="selectedCategory === category.id ? 'active' : ''">
          <p class="m-0">{{columnNameByLanguage(category.attributes)}}</p>
        </li>
    </ul>
    </div>
    <div class="col-12 col-md-10 carousel-container">
      <div class="carousel">
        <ngb-carousel [interval]="3000" [showNavigationIndicators]="true">
            <ng-template ngbSlide *ngFor="let item of carousel">
              <div class="picsum-img-wrapper" [style.--bgColor]="item?.backgroundColor">
                <div class="carousel-content">
                  <div class="carousel-left">
                    <div class="carousel-title-container">
                      <h5 class="carousel-title">{{item.title}}</h5>
                      <h1 class="carousel-text">{{item.paragraph}}</h1>
                      <div class="d-flex el-container">
                        <div class="carousel-button pointer" (click)="redirectTo(item.redirectLink)">
                          <!-- <a [href]="item.redirectLink"> -->
                            {{item.carouselButton}}
                          <!-- </a> -->
                        </div>
                        <div>
                          <img src="../../../assets/icons/right-arrow.svg" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <img class="d-block img object-fit-cover"
                  [src]="item.carousel_image"  
                  alt="Slide">
                </div>
              </div>
            </ng-template>
        </ngb-carousel>
        </div>
    </div>
  </div>

  <section class="margin-5-top">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-title">{{homeData?.flashSalesHeader.firstHeader}}</h2>
    </div>
    <div class="d-flex align-items-center mt-2 justify-content-between flex-wrap">
      <div class="left-side d-flex flex-wrap align">
      <h2>{{homeData?.flashSalesHeader.secondHeader}}</h2>
      <div class="countdown">
        <div>
          <span class="time-label">{{'COUNT_DOWN.DAYS' | translate}}</span>
          <span class="time-value time-value-banner days">{{ flashSalesCountdown.days }}</span>
        </div>
        <div class="colon">:</div>
        <div>
          <span class="time-label">{{'COUNT_DOWN.HOURS' | translate}}</span>
          <span class="time-value time-value-banner hours">{{ flashSalesCountdown.hours }}</span>
        </div>
        <div class="colon">:</div>
        <div>
          <span class="time-label">{{'COUNT_DOWN.MINUTES' | translate}}</span>
          <span class="time-value time-value-banner minutes">{{ flashSalesCountdown.minutes }}</span>
        </div>
        <div class="colon">:</div>
        <div>
          <span class="time-label">{{'COUNT_DOWN.SECONDS' | translate}}</span>
          <span class="time-value time-value-banner seconds">{{ flashSalesCountdown.seconds }}</span>
        </div>
      </div>
    </div>
      <div class="navigations d-flex">
        <div class="swiper-button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path d="M8.70711 1.712C9.09763 1.3215 9.09763 0.688296 8.70711 0.297796C8.31658 -0.0927038 7.68342 -0.0927038 7.29289 0.297796L8.70711 1.712ZM1 8.00489L0.292893 7.29778C-0.09763 7.68831 -0.09763 8.32147 0.292893 8.71199L1 8.00489ZM7.29289 15.712C7.68342 16.1025 8.31658 16.1025 8.70711 15.712C9.09763 15.3215 9.09763 14.6883 8.70711 14.2978L7.29289 15.712ZM1 7.0049C0.447715 7.0049 0 7.4526 0 8.00489C0 8.55717 0.447715 9.00489 1 9.00489V7.0049ZM17 9.00489C17.5523 9.00489 18 8.55717 18 8.00489C18 7.4526 17.5523 7.0049 17 7.0049V9.00489ZM7.29289 0.297796L0.292893 7.29778L1.70711 8.71199L8.70711 1.712L7.29289 0.297796ZM0.292893 8.71199L7.29289 15.712L8.70711 14.2978L1.70711 7.29778L0.292893 8.71199ZM1 9.00489H17V7.0049H1V9.00489Z" fill="black"/>
        </svg></div>
        <div class="swiper-button-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
          <path d="M1.5 7.24561C1.08579 7.24561 0.75 7.5814 0.75 7.99561C0.75 8.40982 1.08579 8.74561 1.5 8.74561V7.24561ZM18 7.99561L18.5303 8.52594C18.8232 8.23305 18.8232 7.75817 18.5303 7.46528L18 7.99561ZM11.5303 0.465237C11.2374 0.172337 10.7626 0.172337 10.4697 0.465237C10.1768 0.758137 10.1768 1.23304 10.4697 1.52594L11.5303 0.465237ZM10.4697 14.4653C10.1768 14.7582 10.1768 15.233 10.4697 15.5259C10.7626 15.8188 11.2374 15.8188 11.5303 15.5259L10.4697 14.4653ZM1.5 8.74561H18V7.24561H1.5V8.74561ZM10.4697 1.52594L17.4697 8.52594L18.5303 7.46528L11.5303 0.465237L10.4697 1.52594ZM17.4697 7.46528L10.4697 14.4653L11.5303 15.5259L18.5303 8.52594L17.4697 7.46528Z" fill="black"/>
        </svg></div>
      </div>
    </div>
  </section>
  <section class="tab-content pb-sm-4 margin-2-top">
    <div class="swiper-products">
      <div class="swiper-wrapper">
        <div *ngFor="let item of filteredProducts" class="swiper-slide">
          <!-- Product Card -->
          <div class="card product-card pb-2">
            <div class="product-image-container">
              <p role="button" class="d-block" data-bs-toggle="modal" (click)="productDetails(item.id)">
                <img class="card-img-top" [src]="item.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')" alt="Image">
              </p>
              <div class="d-flex justify-content-between">
                <div class="discount absolute"><span class="discount-percentage">-50%</span></div>
                <div class="icons-container absolute">
                  <!-- Wishlist Icons -->
                  <div class="wishlist" (click)="item.productDetails.length > 1 ? productModal(wishListProductDetails, item) : (item.productDetails[0].qt > 0 ? addToWishList(item) : '')">
                    <img *ngIf="isInWishList(item); else notFavorite" [src]="'../../../assets/icons/is-favorite.svg'" alt="favorite">
                    <ng-template #notFavorite>

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.6328 6.64738C21.3187 5.91997 20.8657 5.26079 20.2992 4.70675C19.7323 4.15107 19.064 3.70947 18.3305 3.40597C17.5699 3.09002 16.7541 2.9283 15.9305 2.93019C14.775 2.93019 13.6477 3.2466 12.668 3.84425C12.4336 3.98722 12.2109 4.14425 12 4.31535C11.7891 4.14425 11.5664 3.98722 11.332 3.84425C10.3523 3.2466 9.225 2.93019 8.06953 2.93019C7.2375 2.93019 6.43125 3.08957 5.66953 3.40597C4.93359 3.71066 4.27031 4.14894 3.70078 4.70675C3.13359 5.26017 2.6805 5.9195 2.36719 6.64738C2.04141 7.40441 1.875 8.20832 1.875 9.03566C1.875 9.81613 2.03438 10.6294 2.35078 11.4568C2.61563 12.1482 2.99531 12.8653 3.48047 13.5896C4.24922 14.7357 5.30625 15.931 6.61875 17.1427C8.79375 19.1513 10.9477 20.5388 11.0391 20.595L11.5945 20.9513C11.8406 21.1083 12.157 21.1083 12.4031 20.9513L12.9586 20.595C13.05 20.5364 15.2016 19.1513 17.3789 17.1427C18.6914 15.931 19.7484 14.7357 20.5172 13.5896C21.0023 12.8653 21.3844 12.1482 21.6469 11.4568C21.9633 10.6294 22.1227 9.81613 22.1227 9.03566C22.125 8.20832 21.9586 7.40441 21.6328 6.64738ZM12 19.0974C12 19.0974 3.65625 13.7513 3.65625 9.03566C3.65625 6.64738 5.63203 4.71144 8.06953 4.71144C9.78281 4.71144 11.2688 5.66769 12 7.06457C12.7312 5.66769 14.2172 4.71144 15.9305 4.71144C18.368 4.71144 20.3438 6.64738 20.3438 9.03566C20.3438 13.7513 12 19.0974 12 19.0974Z" fill="#9292A3"/>
                      </svg>
                    </ng-template>
                      
                  </div>
                  <div class="see-product" (click)="productDetails(item.id)" data-bs-toggle="modal">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                      </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-1 pb-2">
              <h3 class="product-title fs-md">
                <p  (click)="productDetails(item.id)" data-bs-toggle="modal">
                  {{ (currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' | translate }}
                </p>
              </h3>
              <div class="d-flex align-items-center justify-content-start">
                <div class="product-price-container">
                  <span class="product-price">{{ item.productDetails[0]?.sellingPriceWithVat }} {{"currency.CURRENCY_VALUE" | translate }}</span>
                </div>
              </div>
            </div>
            <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0" (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item) : '')">
              {{'products.ADD-TO-CART'|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex margin-3-top justify-content-center all-products">
      <button type="button" class="custom-btn mx-1" (click)="goToProducts()">
        {{'home.MORE-PRODUCTS' | translate}} <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </section>
  
  
  <hr class="margin-5-top margin-2-bottom">

  <section class="margin-5-top">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-title">{{homeData?.categoriesHeader.firstHeader}}</h2>
    </div>
    <div class="d-flex align-items-center margin-2-top justify-content-between flex-wrap">
      <div class="left-side d-flex">
      <h2>{{homeData?.categoriesHeader.secondHeader}}</h2>
    </div>
      <div class="navigations d-flex">
        <div class="swiper-button-prev-categories">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path d="M8.70711 1.712C9.09763 1.3215 9.09763 0.688296 8.70711 0.297796C8.31658 -0.0927038 7.68342 -0.0927038 7.29289 0.297796L8.70711 1.712ZM1 8.00489L0.292893 7.29778C-0.09763 7.68831 -0.09763 8.32147 0.292893 8.71199L1 8.00489ZM7.29289 15.712C7.68342 16.1025 8.31658 16.1025 8.70711 15.712C9.09763 15.3215 9.09763 14.6883 8.70711 14.2978L7.29289 15.712ZM1 7.0049C0.447715 7.0049 0 7.4526 0 8.00489C0 8.55717 0.447715 9.00489 1 9.00489V7.0049ZM17 9.00489C17.5523 9.00489 18 8.55717 18 8.00489C18 7.4526 17.5523 7.0049 17 7.0049V9.00489ZM7.29289 0.297796L0.292893 7.29778L1.70711 8.71199L8.70711 1.712L7.29289 0.297796ZM0.292893 8.71199L7.29289 15.712L8.70711 14.2978L1.70711 7.29778L0.292893 8.71199ZM1 9.00489H17V7.0049H1V9.00489Z" fill="black"/>
          </svg>
        </div>
        <div class="swiper-button-next-categories">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
            <path d="M1.5 7.24561C1.08579 7.24561 0.75 7.5814 0.75 7.99561C0.75 8.40982 1.08579 8.74561 1.5 8.74561V7.24561ZM18 7.99561L18.5303 8.52594C18.8232 8.23305 18.8232 7.75817 18.5303 7.46528L18 7.99561ZM11.5303 0.465237C11.2374 0.172337 10.7626 0.172337 10.4697 0.465237C10.1768 0.758137 10.1768 1.23304 10.4697 1.52594L11.5303 0.465237ZM10.4697 14.4653C10.1768 14.7582 10.1768 15.233 10.4697 15.5259C10.7626 15.8188 11.2374 15.8188 11.5303 15.5259L10.4697 14.4653ZM1.5 8.74561H18V7.24561H1.5V8.74561ZM10.4697 1.52594L17.4697 8.52594L18.5303 7.46528L11.5303 0.465237L10.4697 1.52594ZM17.4697 7.46528L10.4697 14.4653L11.5303 15.5259L18.5303 8.52594L17.4697 7.46528Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>
  </section>
  <section class="tab-content pb-sm-5 margin-2-top">
    <div class="swiper-categories">
      <div class="swiper-wrapper">
        <div  *ngFor="let item of categoriesFromConome" class="swiper-slide">
          <!-- Categories Card -->
          <div class="categories-boxes" (click)="redirectToProducts('products', item)">
            <div class="content">
              <img style="height: 55px;" src="../../../assets/images/lozztoys-logo.png" alt="icon">
              <p class="margin-0">{{columnNameByLanguage(item)}}</p>                
            </div>
          </div> 
        </div>
      </div>
    </div>
  </section>
  <hr class="margin-2-top margin-2-bottom">

  <section class="margin-5-top">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-title">{{homeData?.bestSellingHeader.firstHeader}}</h2>
    </div>
    <div class="d-flex align-items-center margin-2-top justify-content-between flex-wrap">
      <div class="left-side d-flex">
      <h2>{{homeData?.bestSellingHeader.secondHeader}}</h2>
    </div>
      <div class="view-all d-flex">
        <div class="view-all-button">
          <p class="margin-0">{{"home.VIEW_ALL" | translate}}</p>
        </div>
      </div>
    </div>
  </section>
  <section class="tab-content pb-sm-5 margin-2-top">
    <div class="grid-container pt-3 pt-sm-0 px-1">
      <div *ngFor="let item of topProducts;let i = index;" >
        <div class="card product-card pb-2">
          <div class="product-image-container">
            <p role="button" class="d-block" data-bs-toggle="modal" (click)="productDetails(item.id)">
              <img class="card-img-top"
                [src]="item?.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item?.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
                alt="Image">
              <!--  </p> -->
  
            <div class="d-flex justify-content-between">
              <div class="discount absolute"><span class="discount-percentage"></span></div>
              <div class="icons-container absolute">
                <div class="wishlist" (click)="item.productDetails.length > 1 ? productModal(wishListProductDetails, item) : (item.productDetails[0].qt > 0 ? addToWishList(item) : '')">
                  <img *ngIf="isInWishList(item); else notFavorite" [src]="'../../../assets/icons/is-favorite.svg'" alt="favorite">
                  <ng-template #notFavorite>

                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.6328 6.64738C21.3187 5.91997 20.8657 5.26079 20.2992 4.70675C19.7323 4.15107 19.064 3.70947 18.3305 3.40597C17.5699 3.09002 16.7541 2.9283 15.9305 2.93019C14.775 2.93019 13.6477 3.2466 12.668 3.84425C12.4336 3.98722 12.2109 4.14425 12 4.31535C11.7891 4.14425 11.5664 3.98722 11.332 3.84425C10.3523 3.2466 9.225 2.93019 8.06953 2.93019C7.2375 2.93019 6.43125 3.08957 5.66953 3.40597C4.93359 3.71066 4.27031 4.14894 3.70078 4.70675C3.13359 5.26017 2.6805 5.9195 2.36719 6.64738C2.04141 7.40441 1.875 8.20832 1.875 9.03566C1.875 9.81613 2.03438 10.6294 2.35078 11.4568C2.61563 12.1482 2.99531 12.8653 3.48047 13.5896C4.24922 14.7357 5.30625 15.931 6.61875 17.1427C8.79375 19.1513 10.9477 20.5388 11.0391 20.595L11.5945 20.9513C11.8406 21.1083 12.157 21.1083 12.4031 20.9513L12.9586 20.595C13.05 20.5364 15.2016 19.1513 17.3789 17.1427C18.6914 15.931 19.7484 14.7357 20.5172 13.5896C21.0023 12.8653 21.3844 12.1482 21.6469 11.4568C21.9633 10.6294 22.1227 9.81613 22.1227 9.03566C22.125 8.20832 21.9586 7.40441 21.6328 6.64738ZM12 19.0974C12 19.0974 3.65625 13.7513 3.65625 9.03566C3.65625 6.64738 5.63203 4.71144 8.06953 4.71144C9.78281 4.71144 11.2688 5.66769 12 7.06457C12.7312 5.66769 14.2172 4.71144 15.9305 4.71144C18.368 4.71144 20.3438 6.64738 20.3438 9.03566C20.3438 13.7513 12 19.0974 12 19.0974Z" fill="#9292A3"/>
                    </svg>
                  </ng-template>
                </div>
                <div class="see-product"  (click)="productDetails(item.id)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                    </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-1 pb-2">
            <h3 class="product-title fs-md">
              <p  (click)="productDetails(item.id)" data-bs-toggle="modal">
                {{ (currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' | translate }}
              </p>
            </h3>
            <div class="d-flex align-items-center justify-content-start">
              <div class="product-price-container"><span class="product-price">{{item.productDetails[0]?.sellingPriceWithVat}}
                  {{"currency.CURRENCY_VALUE"| translate}}</span></div>
            </div>
          </div>
          <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0" (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item) : '')">{{'products.ADD-TO-CART'|translate}}</div>

          <!-- <div class="add-to-cart" [class.disabled]="item.productDetails[0]?.qt <= 0" (click)="item.productDetails[0]?.qt <= 0 ? '' : addToCart(item)">{{'products.ADD-TO-CART'|translate}}</div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="margin-5-top" *ngIf="promotionSectionData">
    <div class="banner">
      <div class="row margin-0">
        <div class="col-12 col-md-6 banner-right-side">
          <div class="d-flex justify-content-start">
            <p class="banner-title">{{promotionSectionData.label}}</p>
          </div>
          <div class="banner-subtitle">
            <h1 class="banner-subtitle-text lg-screen">{{promotionSectionData.header1}} <br>{{promotionSectionData.header2}}</h1>
            <h1 class="banner-subtitle-text sm-screen">{{promotionSectionData.header1}} {{promotionSectionData.header2}}</h1>
          </div>

          <div class="row mb-3 img-container">
            <img src="../../../assets/images/bycicle-removebg-preview.png" alt="bycicle">
          </div>
          <div class="countdown-banner d-flex">
            <div class="countdown-container">
              <div class="d-flex justify-content-center time-value-banner days">{{ promotionCountdown.days }}</div>
              <div class="d-flex justify-content-center">{{'COUNT_DOWN.DAYS' | translate}}</div>
            </div>
            <div class="countdown-container">
              <div class="d-flex justify-content-center time-value-banner hours">{{ promotionCountdown.hours }}</div>
              <div class="d-flex justify-content-center">{{'COUNT_DOWN.HOURS' | translate}}</div>
            </div>
            <div class="countdown-container">
              <div class="d-flex justify-content-center time-value-banner minutes">{{ promotionCountdown.minutes }}</div>
              <div class="d-flex justify-content-center">{{'COUNT_DOWN.MINUTES' | translate}}</div>
            </div>
            <div class="countdown-container">
              <div class="d-flex justify-content-center time-value-banner seconds">{{ promotionCountdown.seconds }}</div>
              <div class="d-flex justify-content-center">{{'COUNT_DOWN.SECONDS' | translate}}</div>
            </div>
          </div>

          <div class="banner-button">
            <p class="margin-0 pointer" (click)="redirectTo(promotionSectionData.redirectButton?.link)">{{promotionSectionData.redirectButton?.text}}</p>
          </div>
        </div>
        <div class="col-6 lg-screen">
          <img src="../../../assets/images/bycicle-removebg-preview.png" alt="bycicle">
        </div>
      </div>
    </div>
  </section>

  <section class="our-products margin-3-top">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-title">{{homeData?.productsHeader.firstHeader}}</h2>
    </div>
    <div class="d-flex align-items-center margin-2-top justify-content-between flex-wrap">
      <div class="left-side d-flex">
      <h2>{{homeData?.productsHeader.secondHeader}}</h2>
    </div>
      <div class="navigations d-flex">
        <div class="swiper-button-prev-categories button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path d="M8.70711 1.712C9.09763 1.3215 9.09763 0.688296 8.70711 0.297796C8.31658 -0.0927038 7.68342 -0.0927038 7.29289 0.297796L8.70711 1.712ZM1 8.00489L0.292893 7.29778C-0.09763 7.68831 -0.09763 8.32147 0.292893 8.71199L1 8.00489ZM7.29289 15.712C7.68342 16.1025 8.31658 16.1025 8.70711 15.712C9.09763 15.3215 9.09763 14.6883 8.70711 14.2978L7.29289 15.712ZM1 7.0049C0.447715 7.0049 0 7.4526 0 8.00489C0 8.55717 0.447715 9.00489 1 9.00489V7.0049ZM17 9.00489C17.5523 9.00489 18 8.55717 18 8.00489C18 7.4526 17.5523 7.0049 17 7.0049V9.00489ZM7.29289 0.297796L0.292893 7.29778L1.70711 8.71199L8.70711 1.712L7.29289 0.297796ZM0.292893 8.71199L7.29289 15.712L8.70711 14.2978L1.70711 7.29778L0.292893 8.71199ZM1 9.00489H17V7.0049H1V9.00489Z" fill="black"/>
          </svg>
        </div>
        <div class="swiper-button-next-categories button-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
            <path d="M1.5 7.24561C1.08579 7.24561 0.75 7.5814 0.75 7.99561C0.75 8.40982 1.08579 8.74561 1.5 8.74561V7.24561ZM18 7.99561L18.5303 8.52594C18.8232 8.23305 18.8232 7.75817 18.5303 7.46528L18 7.99561ZM11.5303 0.465237C11.2374 0.172337 10.7626 0.172337 10.4697 0.465237C10.1768 0.758137 10.1768 1.23304 10.4697 1.52594L11.5303 0.465237ZM10.4697 14.4653C10.1768 14.7582 10.1768 15.233 10.4697 15.5259C10.7626 15.8188 11.2374 15.8188 11.5303 15.5259L10.4697 14.4653ZM1.5 8.74561H18V7.24561H1.5V8.74561ZM10.4697 1.52594L17.4697 8.52594L18.5303 7.46528L11.5303 0.465237L10.4697 1.52594ZM17.4697 7.46528L10.4697 14.4653L11.5303 15.5259L18.5303 8.52594L17.4697 7.46528Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>
  </section>

  <section class="tab-content pb-sm-5 margin-2-top">
    <swiper-container swiperElement [config]="config" init="false" class="swiper">
        <swiper-slide *ngFor="let item of ourProducts; let i = index">
            <div class="card product-card pb-2">
                <div class="product-image-container">
                    <p role="button" class="d-block" data-bs-toggle="modal"  (click)="productDetails(item.id)">
                        <img class="card-img-top"
                            [src]="item?.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item?.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
                            alt="Image">
                        <!-- Product image -->
                    </p>
                    <div class="d-flex justify-content-between">
                        <div class="discount absolute"><span class="discount-percentage"></span></div>
                        <div class="icons-container absolute">
                            <div class="wishlist" (click)="item.productDetails.length > 1 ? productModal(wishListProductDetails, item) : (item.productDetails[0].qt > 0 ? addToWishList(item) : '')">
                                <img *ngIf="isInWishList(item); else notFavorite" [src]="'../../../assets/icons/is-favorite.svg'" alt="favorite">
                                <ng-template #notFavorite>
            
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M21.6328 6.64738C21.3187 5.91997 20.8657 5.26079 20.2992 4.70675C19.7323 4.15107 19.064 3.70947 18.3305 3.40597C17.5699 3.09002 16.7541 2.9283 15.9305 2.93019C14.775 2.93019 13.6477 3.2466 12.668 3.84425C12.4336 3.98722 12.2109 4.14425 12 4.31535C11.7891 4.14425 11.5664 3.98722 11.332 3.84425C10.3523 3.2466 9.225 2.93019 8.06953 2.93019C7.2375 2.93019 6.43125 3.08957 5.66953 3.40597C4.93359 3.71066 4.27031 4.14894 3.70078 4.70675C3.13359 5.26017 2.6805 5.9195 2.36719 6.64738C2.04141 7.40441 1.875 8.20832 1.875 9.03566C1.875 9.81613 2.03438 10.6294 2.35078 11.4568C2.61563 12.1482 2.99531 12.8653 3.48047 13.5896C4.24922 14.7357 5.30625 15.931 6.61875 17.1427C8.79375 19.1513 10.9477 20.5388 11.0391 20.595L11.5945 20.9513C11.8406 21.1083 12.157 21.1083 12.4031 20.9513L12.9586 20.595C13.05 20.5364 15.2016 19.1513 17.3789 17.1427C18.6914 15.931 19.7484 14.7357 20.5172 13.5896C21.0023 12.8653 21.3844 12.1482 21.6469 11.4568C21.9633 10.6294 22.1227 9.81613 22.1227 9.03566C22.125 8.20832 21.9586 7.40441 21.6328 6.64738ZM12 19.0974C12 19.0974 3.65625 13.7513 3.65625 9.03566C3.65625 6.64738 5.63203 4.71144 8.06953 4.71144C9.78281 4.71144 11.2688 5.66769 12 7.06457C12.7312 5.66769 14.2172 4.71144 15.9305 4.71144C18.368 4.71144 20.3438 6.64738 20.3438 9.03566C20.3438 13.7513 12 19.0974 12 19.0974Z" fill="#9292A3"/>
                                  </svg>
                                </ng-template>
                            </div>
                            <div class="see-product" (click)="productDetails(item.id)">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-1 pb-2">
                    <h3 class="product-title fs-md">
                        <p  (click)="productDetails(item.id)" data-bs-toggle="modal">
                            {{(currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' | translate}}
                        </p>
                    </h3>
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="product-price-container"><span class="product-price">{{item.productDetails[0]?.sellingPriceWithVat}}
                            {{"currency.CURRENCY_VALUE"| translate}}</span></div>
                    </div>
                </div>
                <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0" (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item) : '')">{{'products.ADD-TO-CART'|translate}}</div>
            </div>
        </swiper-slide>
    </swiper-container>
    <div class="d-flex margin-3-top justify-content-center all-products">
        <button type="button" class="custom-btn mx-1" (click)="goToProducts()">{{'home.MORE-PRODUCTS' | translate}}
            <i class="bi bi-chevron-right"></i>
        </button>
    </div>
</section>


  <section class="margin-3-top featured">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-title">{{homeData?.newArrival.firstHeader}}</h2>
    </div>
    <div class="d-flex align-items-center margin-2-top">
      <div class="left-side d-flex">
      <h2>{{homeData?.newArrival.secondHeader}}</h2>
    </div>
    </div>
  </section>

  <section class="margin-3-top new-arrival">
    <div class="row">
      <!-- First Column (Full Height) -->
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 first-column lg-screen">
        <div *ngIf="sortedProducts.length > 0" class="item card column-height-100 justify-content-between mt-0">
          <div class="new-arrival-image-container d-flex justify-content-center mt-3" (click)="productDetails(sortedProducts[0].id)">
            <img [src]="sortedProducts[0].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[0].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[0]?.image ? conomeUrl + '/' + sortedProducts[0]?.image : '../assets/images/no-product.jpg')" alt="image">
          </div>
          <div class="new-arrival-text">
            <h4 class="mt-1 new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[0]?.nameSQ :
              sortedProducts[0]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
            <a class="pointer" [class.disabled]="sortedProducts[0].productDetails.length === 1 && sortedProducts[0].productDetails[0].qt <= 0" (click)="(sortedProducts[0].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[0]) : (sortedProducts[0].productDetails[0].qt > 0 ? buyNow(sortedProducts[0], sortedProducts[0].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
          </div>
        </div>
      </div>
  
      <!-- Small screen -->
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 sm-screen">
        <div *ngIf="sortedProducts.length > 0" class="item d-flex justify-content-between second-column">
          <div class="new-arrival-text second-column-title">
            <h4 class="margin-2-top new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[1]?.nameSQ :
              sortedProducts[0]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
            <a class="pointer" [class.disabled]="sortedProducts[0].productDetails.length === 1 && sortedProducts[0].productDetails[0].qt <= 0" (click)="(sortedProducts[0].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[0]) : (sortedProducts[0].productDetails[0].qt > 0 ? buyNow(sortedProducts[0], sortedProducts[0].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
          </div>
  
          <div class="new-arrival-image-container-second-column d-flex justify-content-center second-column-image" (click)="productDetails(sortedProducts[0].id)">
            <img [src]="sortedProducts[0].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[0].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[0]?.image ? conomeUrl + '/' + sortedProducts[0]?.image : '../assets/images/no-product.jpg')" alt="image">
          </div>
        </div>
      </div>
  
      <!-- Second Column -->
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <!-- First Row in Second Column -->
        <div *ngIf="sortedProducts.length > 1" class="item d-flex justify-content-between second-column mt-sm">
          <div class="new-arrival-text second-column-title">
            <h4 class="margin-2-top new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[1]?.nameSQ :
              sortedProducts[1]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
            <a class="pointer" [class.disabled]="sortedProducts[1].productDetails.length === 1 && sortedProducts[1].productDetails[0].qt <= 0" (click)="(sortedProducts[1].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[1]) : (sortedProducts[1].productDetails[0].qt > 0 ? buyNow(sortedProducts[1], sortedProducts[1].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
          </div>
  
          <div class="new-arrival-image-container-second-column d-flex justify-content-center second-column-image" (click)="productDetails(sortedProducts[1].id)">
            <img [src]="sortedProducts[1].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[1].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[1]?.image ? conomeUrl + '/' + sortedProducts[1]?.image : '../assets/images/no-product.jpg')" alt="image">

          </div>
        </div>

        <!-- Second Row in Second Column, divided into two columns in large screen -->
        <div class="row lg-screen last-row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 second-row w-5" *ngIf="sortedProducts.length > 2">
            <div class="item d-flex flex-column justify-content-between column-height-100">
    
              <div class="new-arrival-image-container-second-column d-flex justify-content-center" (click)="productDetails(sortedProducts[2].id)">
                <img class="object-fit-contain" [src]="sortedProducts[2].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[2].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[2]?.image ? conomeUrl + '/' + sortedProducts[2]?.image : '../assets/images/no-product.jpg')" alt="image">

              </div>
              <div class="new-arrival-text">
                <h4 class="mt-1 new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[2]?.nameSQ :
                  sortedProducts[2]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
                <a class="pointer" [class.disabled]="sortedProducts[2].productDetails.length === 1 && sortedProducts[2].productDetails[0].qt <= 0" (click)="(sortedProducts[2].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[2]) : (sortedProducts[2].productDetails[0].qt > 0 ? buyNow(sortedProducts[2], sortedProducts[2].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
              </div>
            </div>
          </div>
    
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 second-row w-5" *ngIf="sortedProducts.length > 3">
            <div class="item d-flex flex-column justify-content-between column-height-100">
    
              <div class="new-arrival-image-container-second-column d-flex justify-content-center" (click)="productDetails(sortedProducts[3].id)">
                <img class="object-fit-contain" [src]="sortedProducts[3].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[3].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[3]?.image ? conomeUrl + '/' + sortedProducts[3]?.image : '../assets/images/no-product.jpg')" alt="image">
              </div>
              <div class="new-arrival-text">
                <h4 class="mt-1 new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[3]?.nameSQ :
                  sortedProducts[3]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
                <a class="pointer" [class.disabled]="sortedProducts[3].productDetails.length === 1 && sortedProducts[3].productDetails[0].qt <= 0" (click)="(sortedProducts[3].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[3]) : (sortedProducts[3].productDetails[0].qt > 0 ? buyNow(sortedProducts[3], sortedProducts[3].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
        <!-- Small screen -->
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 sm-screen">
          <div *ngIf="sortedProducts.length > 2" class="item d-flex justify-content-between second-column">
            <div class="new-arrival-text second-column-title">
              <h4 class="margin-2-top new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[2]?.nameSQ :
                sortedProducts[2]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
              <a class="pointer" [class.disabled]="sortedProducts[2].productDetails.length === 1 && sortedProducts[2].productDetails[0].qt <= 0" (click)="(sortedProducts[2].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[2]) : (sortedProducts[2].productDetails[0].qt > 0 ? buyNow(sortedProducts[2], sortedProducts[2].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>

            </div>
    
            <div class="new-arrival-image-container-second-column d-flex justify-content-center second-column-image" (click)="productDetails(sortedProducts[2].id)">
              <img class="object-fit-contain" [src]="sortedProducts[2].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[2].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[2]?.image ? conomeUrl + '/' + sortedProducts[2]?.image : '../assets/images/no-product.jpg')" alt="image">
            </div>
          </div>
        </div>
    
        <!-- Small screen -->
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 sm-screen">
          <div *ngIf="sortedProducts.length > 3" class="item d-flex justify-content-between second-column">
            <div class="new-arrival-text second-column-title">
              <h4 class="margin-2-top new-arrival-title">{{(currentLanguage === 'sq' ? sortedProducts[3]?.nameSQ :
                sortedProducts[3]?.nameMK) || 'products.NO-NAME' |translate}}</h4>
              <a class="pointer" [class.disabled]="sortedProducts[3].productDetails.length === 1 && sortedProducts[3].productDetails[0].qt <= 0" (click)="(sortedProducts[3].productDetails.length > 1 ? productModal(hasProductDetails, sortedProducts[3]) : (sortedProducts[3].productDetails[0].qt > 0 ? buyNow(sortedProducts[3], sortedProducts[3].productDetails[0]) : '')); isBuyNow = true">{{'home.SHOP-NOW' | translate}}</a>
            </div>
    
            <div class="new-arrival-image-container-second-column d-flex justify-content-center second-column-image" (click)="productDetails(sortedProducts[3].id)">
              <img class="object-fit-contain" [src]="sortedProducts[3].productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + sortedProducts[3].productDetails[0]?.allProductDetailImages[0] : (sortedProducts[3]?.image ? conomeUrl + '/' + sortedProducts[3]?.image : '../assets/images/no-product.jpg')" alt="image">

            </div>
          </div>
        </div>
  </div>
  </section>


  <section class="service-section">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4" *ngFor="let item of serviceHighlights">
        <div class="box">
          <div class="circle d-flex justify-content-center">
            <div class="gray-cirle">
              <div class="orange-circle">
                <img class="icon pointer" src="{{cmsUrl + item?.image?.data.attributes?.url}}" alt="{{item?.image?.data.attributes?.alternativeText}}">

              </div>
            </div>
          </div>           
          <div class="title margin-2-top d-flex justify-content-center">
            <h4>{{item.title}}</h4>
          </div>
          <div class="description mt-1 d-flex justify-content-center">
            {{item.description}}
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #wishListProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute"
    type="button"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
  </button>
    <div class="px-lg-3">
        <div class="has-product-details">
          <div class="pb-3 mt-2">
            <div class="mb-3">
              <span class="h3 fw-normal text-accent me-1">
                {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            </div>
            <div class="mb-3 d-flex align-items-center">
              <button [class.disabled]="currentProductDetail.qt <= 0" (click)="addToWishList(currentProduct)" class="btn btn-primary btn-shadow d-block w-100">
                <i class="ci-cart fs-lg me-2"></i>
                {{'WISHLIST.ADD_TO_WISHLIST'|translate}}
              </button>
            </div>
          </div>
          <div class="mt-4">
            <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
            <div class="product-details-names pointer">
              <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails" (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0" [class.selected]="detail === currentProductDetail">
                {{ detail.productName }}
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>


<ng-template #hasProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute"
    type="button"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
  </button>
    <div class="px-lg-3">
        <div class="has-product-details">
          <div class="pb-3 mt-2">
            <div class="mb-3">
              <span class="h3 fw-normal text-accent me-1">
                {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            </div>
            <div class="mb-3 d-flex align-items-center">
              <button [class.disabled]="currentProductDetail.qt <= 0" (click)="buyOrAddToCart()" class="btn btn-primary btn-shadow d-block w-100">
                <span *ngIf="isBuyNow; else notBuyNow">
                  <!-- <img  [src]="'../../../assets/icons/is-favorite.svg'" alt="favorite"> -->
                  {{'home.SHOP-NOW' | translate}}
                </span>
                <ng-template #notBuyNow>

                  <i class="ci-cart fs-lg me-2"></i>
                  {{'products.ADD-TO-CART' | translate}}

                </ng-template>
              </button>
            </div>
          </div>
          <div class="mt-4">
            <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
            <div class="product-details-names pointer">
              <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails" (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0" [class.selected]="detail === currentProductDetail">
                {{ detail.productName }}
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>

<div class="success-message message-container" *ngIf="showSuccessMessage">
  <div class="success-message">
    {{ successMessage }}
  </div>
</div>
<div class="warning-message message-container" *ngIf="showWarningMessage">
  <div class="warning-message">
    {{ successMessage }}
  </div>
</div>
