<div>
  <app-banner title="{{ 'menu.CART' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>
</div>

<section>
  <div class="bg-light rounded-3 px-4 py-3 position-relative">
    <div class="container product-details-container px-lg-3">
          <div class="product-gallery">
            <div class="product-gallery-preview order-sm-2">

              <div *ngIf="allImagesProductDetaill?.length>0; then allImages else justOne"></div>
              <ng-template #allImages>
                <div class="active first-image" id="first" >
                <img class="image-zoom"
                src="{{allImagesProductDetaill && allImagesProductDetaill.length > 0 && environment.conomeUrl + '/' + allImagesProductDetaill[0]}}"
                  alt="Product image"
                  >
                </div>
                </ng-template>
                <ng-template #justOne>
              <div class="active first-image" id="first">

                  <img class="image-zoom"
                  src="{{environment.conomeUrl + '/' + productDetail?.image}}"
                  alt="Product"
                  >
                </div>
                </ng-template>
            </div>

             <!-- Side images -->
             <div class="product-gallery-thumblist order-sm-1" *ngIf="allImagesProductDetaill && allImagesProductDetaill.length>0">
              <ng-container *ngFor="let image of allImagesProductDetaill; let i=index">
                <a 
                  (click)="filterImg($event, environment.conomeUrl + '/' + image)"
                  class="product-gallery-thumblist-item"
                  [ngClass]="i===0?'active':''"
                  href="javascript:void(0);"
                >
                  <img class="h-100" src="{{allImagesProductDetaill && allImagesProductDetaill.length > 0 && environment.conomeUrl + '/' + image}}" alt="Product thumb">
                </a>
              </ng-container>
            </div>

          </div>
        <div class="detail-column">
          <div class="product-details mt-2">
            <div class="d-flex justify-content-between gap-10px">
              <h4>{{(currentLanguage === 'sq' ? productDetail?.nameSQ : productDetail?.nameMK) || 'products.NO-NAME' | translate }}</h4>
            </div>
            
            <div class="d-flex my-2 gap-10px">          
              <div class="" *ngIf="currentProductDetail?.noProduct || productDetail?.noProduct">
                <div class="product-number">{{'product-details.PRODUCT-NUMBER'|translate}}: {{currentProductDetail.noProduct ? currentProductDetail.noProduct : productDetail?.noProduct}}</div>
              </div>
                <span *ngIf="currentProductDetail?.noProduct || productDetail?.noProduct">|</span>
              <div>
                <span *ngIf="currentProductDetail?.qt > 0; then inStock else outStock"></span>
                <ng-template #inStock><span class="available">{{'products.IN_STOCK' | translate}}</span></ng-template>
                <ng-template #outStock><span class="not-available">{{'products.OUT_OF_STOCK' | translate}}</span></ng-template>
              </div>
  
            </div>
            <div class="mb-3">
              <span class="h3 fw-normal me-1">
                {{currentProductDetail?.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            </div>

            <div class="scrollable-description" markdown *ngIf="productDetail?.notes">
              {{productDetail.notes}}            
            </div>
          </div>
          <div class="divider my-2"></div>

          <div class="my-4 product-size" *ngIf="productDetail?.productDetails?.length>1">
            <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
            <div class="product-details-names pointer">
              <div class="product-details-name" *ngFor="let detail of productDetail?.productDetails" (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0" [class.selected]="detail === currentProductDetail">
                {{truncateText(detail?.productName, 5) }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap gap-10px justify-content-between align-items-center buttons-section my-2">
            <div class="number-input-container">
              <div class="button minus" (click)="decreaseValue()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17.0312 9.25781H2.96875C2.88281 9.25781 2.8125 9.32812 2.8125 9.41406V10.5859C2.8125 10.6719 2.88281 10.7422 2.96875 10.7422H17.0312C17.1172 10.7422 17.1875 10.6719 17.1875 10.5859V9.41406C17.1875 9.32812 17.1172 9.25781 17.0312 9.25781Z" stroke="#5e5e5e" fill="#5e5e5e"/>
                </svg>
              </div>
              <input type="number" [(ngModel)]="value" (change)="limitNumberLength($event, currentProductDetail?.qt)" (input)="limitNumberLength($event, currentProductDetail?.qt)"  [max]="currentProductDetail?.qt"/>
              <div class="button plus" (click)="increaseValue()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M9.41406 2.96875H10.5859C10.6901 2.96875 10.7422 3.02083 10.7422 3.125V16.875C10.7422 16.9792 10.6901 17.0312 10.5859 17.0312H9.41406C9.3099 17.0312 9.25781 16.9792 9.25781 16.875V3.125C9.25781 3.02083 9.3099 2.96875 9.41406 2.96875Z" stroke="#5e5e5e" fill="#5e5e5e"/>
                  <path d="M3.4375 9.25781H16.5625C16.6667 9.25781 16.7188 9.3099 16.7188 9.41406V10.5859C16.7188 10.6901 16.6667 10.7422 16.5625 10.7422H3.4375C3.33333 10.7422 3.28125 10.6901 3.28125 10.5859V9.41406C3.28125 9.3099 3.33333 9.25781 3.4375 9.25781Z" stroke="#5e5e5e" fill="#5e5e5e"/>
                </svg>
              </div>
            </div>
            <div class="d-flex gap-10px align-items-center">
              <button [class.disabled]="currentProductDetail?.qt <= 0" (click)="addToCart(productDetail, currentProductDetail)" class="btn btn-primary btn-shadow d-block w-100">
                <i class="ci-cart fs-lg me-2"></i>
                {{'products.ADD-TO-CART'|translate}}
              </button>

              <div >
                <button class="btn btn-primary btn-shadow d-block w-100" [class.disabled]="currentProductDetail?.qt <= 0" (click)="buyNow(productDetail, currentProductDetail)" routerLinkActive="router-link-active" >               
                  {{'products.BUY_NOW'|translate}}
                </button>
              </div>
            </div>

  
            <div class="wishlist" [class.disabled]="isOutOfStock(productDetail)" (click)="productDetail.productDetails.length > 1 ? addToWishList(productDetail, currentProductDetail) : (productDetail.productDetails[0].qt > 0 ? addToWishList(productDetail, currentProductDetail) : '')">
              <img [src]="isInWishList(productDetail) ? '../../../assets/icons/is-favorite.svg' : '../../../assets/icons/favorite.svg'" alt="favorite">
            </div>
          </div>
          <div class="services-section margin-3-top" *ngIf="serviceData && serviceData.length > 0">
            <div  *ngFor="let service of serviceData; let last = last" class="w-100">
              <div class="service">
                <div>
                  <img class="image-service"
                    src="{{ environment.backendUrl + service.image.data.attributes?.url}}"
                    alt="{{service.image.data.attributes?.alternativeText}}"
                  >
                </div>
                <div class="text">
                  <div class="title">
                    {{service.title}}
                  </div>
                  <div>
                    <span *ngIf="service.description">
                      {{service.description}} 
                    </span>
                    <span class="pointer description" *ngIf="service.descriptionWithLink" (click)="redirectTo(service?.link)">
                      {{service?.descriptionWithLink}}
                    </span>
  
                  </div>
                </div>
              </div>
              <div *ngIf="!last" class="divider"></div>
  
            </div>
          </div>
        </div>
    </div>
  </div>
</section>

<section class="container tab-content margin-2-top">
  <div class="d-flex margin-3-bottom align-items-center">
    <div class="orange-box"></div>
    <h2 class="text-title">{{relatedHeader?.firstHeader}}</h2>
  </div>
  <div class="d-flex align-items-center margin-3-bottom justify-content-between flex-wrap" *ngIf="relatedHeader?.secondHeader">
    <div class="left-side d-flex">
    <h2>{{relatedHeader?.secondHeader}}</h2>
  </div>
  </div>

  <div class="row pt-3 pt-sm-0 px-1">
    <div *ngFor="let item of relatedItems;let i = index;" class="col-lg-3 col-md-3 col-sm-6 mb-grid-gutter">
      <div class="card product-card border pb-2">
        <div class="product-image-container">
          <p role="button" class="d-block" data-bs-toggle="modal" (click)="productDetails(item.id)">
            <img class="card-img-top"
              [src]="item.productDetails[0]?.allProductDetailImages[0] ? environment.conomeUrl + '/' + item.productDetails[0]?.allProductDetailImages[0] : (item?.image ? environment.conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
              alt="Image">
          </p>
          <div class="d-flex justify-content-between">
            <div class="discount absolute"><span class="discount-percentage">-50%</span></div>
            <div class="icons-container absolute">
              <div class="wishlist" [class.disabled-on-hover]="isOutOfStock(item)" (click)="item.productDetails.length > 1 ? productModal(wishListProductDetails, item) : (item.productDetails[0].qt > 0 ? addToWishList(item, item.productDetails[0]) : '')">
                <img [src]="isInWishListRelated(item) ? '../../../assets/icons/is-favorite.svg' : '../../../assets/icons/favorite.svg'" alt="favorite">
              </div>
              <div class="see-product" (click)="productDetails(item.id)" data-bs-toggle="modal"><img
                  src="../../../assets/icons/eye.svg" alt="eye"></div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-2">
          <h3 class="product-title fs-md">
            <p (click)="productDetails(item.id)" data-bs-toggle="modal">
              {{ (currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' | translate }}
            </p>
          </h3>
          <div class="d-flex align-items-center justify-content-start">
            <div class="product-price-container">
              <span class="product-price">{{ item.productDetails[0]?.sellingPriceWithVat }} {{"currency.CURRENCY_VALUE"
                | translate }}</span>
            </div>
          </div>
        </div>
        <div class="add-to-cart" [class.disabled]="isOutOfStock(item)"
          (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item, item.productDetails[0]) : '')">
          {{'products.ADD-TO-CART'|translate}}
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #wishListProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute" type="button" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
    <div class="px-lg-3">
      <div class="has-product-details">
        <div class="pb-3 mt-2">
          <div class="mb-3">
            <span class="h3 fw-normal text-accent me-1">
              {{currentRelatedProductDetail?.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
            </span>
          </div>
          <div class="mb-3 d-flex align-items-center">
            <button [class.disabled]="currentRelatedProductDetail?.qt <= 0" (click)="addToWishList(currentRelatedProduct, currentRelatedProductDetail)"
              class="btn btn-primary btn-shadow d-block w-100">
              {{'WISHLIST.ADD_TO_WISHLIST'|translate}}
            </button>
          </div>
        </div>
        <div class="mt-4">
          <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
          <div class="product-details-names pointer">
            <div class="product-details-name" *ngFor="let detail of currentRelatedProduct?.productDetails"
              (click)="selectRelatedProduct(detail)" [class.disabled]="detail?.qt <= 0"
              [class.selected]="detail === currentRelatedProductDetail">
              {{ truncateText(detail?.productName, 5) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hasProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute" type="button" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
    <div class="px-lg-3">
      <div class="has-product-details">
        <div class="pb-3 mt-2">
          <div class="mb-3">
            <span class="h3 fw-normal text-accent me-1">
              {{currentRelatedProductDetail?.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
            </span>
          </div>
          <div class="mb-3 d-flex align-items-center">
            <button [class.disabled]="currentRelatedProductDetail?.qt <= 0" (click)="addToCart(currentRelatedProduct, currentRelatedProductDetail)"
              class="btn btn-primary btn-shadow d-block w-100">
              <i class="ci-cart fs-lg me-2"></i>
              {{'products.ADD-TO-CART'|translate}}
            </button>
          </div>
        </div>
        <div class="mt-4">
          <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
          <div class="product-details-names pointer">
            <div class="product-details-name" *ngFor="let detail of currentRelatedProduct?.productDetails"
              (click)="selectRelatedProduct(detail)" [class.disabled]="detail?.qt <= 0"
              [class.selected]="detail === currentRelatedProductDetail">
              {{ truncateText(detail?.productName, 5) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

  <div class="success-message message-container" *ngIf="showSuccessMessage">
    <div class="success-message">
      {{ successMessage }}
    </div>
  </div>

  <div class="warning-message message-container" *ngIf="showWarningMessage">
    <div class="warning-message">
      {{ successMessage }}
    </div>
  </div>