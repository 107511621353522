<div class="container login-content">
    <div class="row d-flex align-items-center">
        <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="image-container">
                <img src="../../../assets/images/lozztoys-logo.png" alt="lozztoys-logo">
              </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <h2>Log In to Lozz Toys</h2>
            <p class="margin-0">Enter your details below</p>

            <form [formGroup]="signInForm" class="margin-2-top">
              <div class="input-container">
                  <input 
                      placeholder="Email" 
                      class="input-field" 
                      type="text" 
                      formControlName="Username">
                  <label for="input-field" class="input-label">Email</label>
                  <span 
                      class="input-highlight" 
                      ></span>
              </div>
          
              <div class="input-container margin-1-top">
                  <input 
                      placeholder="Password" 
                      class="input-field" 
                      type="password" 
                      formControlName="password">
                  <label for="input-field" class="input-label">Password</label>
                  <span 
                      class="input-highlight" 
                      ></span>
              </div>
          
             

          </form>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <button class="login-button d-block w-100" (click)="login(signInForm.value)">Log In</button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <button class="forget-button d-block w-100" (click)="openForgotPasswordModal()">Forget Password</button>
            </div>

          </div>
        </div>
    </div>
</div>


