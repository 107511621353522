import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, AfterViewInit, PLATFORM_ID, Inject, TransferState, makeStateKey } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE } from '../../modules/constants';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { SeoService } from 'src/app/services/seoService/seo.service';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { Observable } from 'rxjs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from '../../services/user/user-profile.service';
Swiper.use([Navigation, Pagination]);

@Component({
    selector: 'app-wishlist',
    // standalone: true,
    // imports: [AppModule],
    templateUrl: './wishlist.component.html',
    styleUrl: './wishlist.component.scss'
})
export class WishlistComponent {

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private modalService: NgbModal,
        private cartService: CartService,
        private seo: SeoService,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private conomeService: ConomeECommerceService,
        private transferState: TransferState,
        private cmsService: LozztoysCmsService,
        private userProfileService: UserProfileService
    ) {
        this.environment = environment;
    }
    productsFromConome: any[] = [];
    categoriesFromConome: any[] = [];
    sortedProducts: any[] = [];
    conomeUrl = environment.conomeUrl
    companyId: number = 0;
    productsFromWishList: any
    currentLanguage = 'sq';
    environment: any;
    isProductDetailModal: boolean = false
    currentProduct: any;
    currentProductImages: any;
    currentProductDetail: any;
    successMessage: string = '';
    showSuccessMessage: boolean = false
    showWarningMessage: boolean = false
    userId: string = '';
    wishListCmsData: any;
    headersFromCms: any;
    productsJustForYou: any;

    ngOnInit() {
        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });
        this.getWishListData();
        this.getHeaders();

        this.translateService.onLangChange.subscribe((res) => {
            this.currentLanguage = res.lang;
            this.getWishListData();
            this.getHeaders();

        });
    }


    getFromWishList(): Observable<any[]> {
        return this.cmsService.getWishList(this.userId).pipe(
            map((res: any) => {
                return res.data
                    .map((item: any) => ({
                        productId: item.attributes.productId,
                        productDetailId: item.attributes.productDetailId,
                        userId: item.attributes.userId
                    }));
            })
        );
    }

    getHeaders() {
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getwishlistHeaders(populateAndLocale).subscribe({
            next: (res) => {
                this.headersFromCms = res.data.attributes;
            }
        })
    }


    getWishListData() {
        if (this.userId === '') {
            const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            this.wishListCmsData = wishListCmsData ? JSON.parse(wishListCmsData) : []
            this.fetchConomeData();

        }
        else {
            this.getFromWishList().subscribe({
                next: (wishlistCmsData: any[]) => {
                    this.wishListCmsData = wishlistCmsData;
                    this.fetchConomeData();
                }
            });

        }

    }
    fetchConomeData() {
        this.conomeService.products$.subscribe(products => {
            this.productsFromConome = products;    
            if (this.productsFromConome.length > 0) {
                this.productsFromWishList = [];
                this.wishListCmsData.forEach((wishlistItem: any) => {
                    const product = this.productsFromConome.find(prod => prod.id === wishlistItem.productId);
                    if (product) {
                        const filteredProduct = {
                            ...product,
                            productDetailId: wishlistItem.productDetailId,
                            productDetails: product.productDetails.filter((detail: any)=> detail.id === wishlistItem.productDetailId) // Filter productDetails
                        };
                        
                        this.productsFromWishList.push(filteredProduct);
                    }
                });
                
                const productsFromWishListIds = this.productsFromWishList.map((item: any) => item.id).filter((id: any) => id !== undefined);
                const productsJustForYouIds = this.productsFromWishList.map((item: any) => item.categoryID).filter((id: any) => id !== undefined);

                let filteredProducts = this.productsFromConome.filter((item: any) =>
                    productsJustForYouIds.includes(item.categoryID) && !productsFromWishListIds.includes(item.id)
                );

                const getRandomSample = (array: any[], size: number) => {
                    const shuffled = array.sort(() => 0.5 - Math.random());
                    return shuffled.slice(0, size);
                };
                if (filteredProducts.length > 4) {
                    this.productsJustForYou = getRandomSample(filteredProducts, 4);
                } else if (filteredProducts.length < 4) {
                    const additionalProducts = this.productsFromConome.filter(
                        (item: any) => !filteredProducts.some((fp: any) => fp.id === item.id) && !productsFromWishListIds.includes(item.id)
                    );
                    const additionalSample = getRandomSample(additionalProducts, 4 - filteredProducts.length);
                    this.productsJustForYou = [...filteredProducts, ...additionalSample];
                } else {
                    this.productsJustForYou = filteredProducts;
                }
            }
        });
    }

    removeFromWishList(product: any, productDetailId: any) {

        if (this.userId === '') {
            let productsArray: any[] = [];
            try {
                const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
                if (storedWishlist) {
                    productsArray = JSON.parse(storedWishlist);
                    if (!Array.isArray(productsArray)) {
                        productsArray = [];
                    }
                }
            } catch (error) {
                console.error('Error parsing wishlist from localStorage:', error);
                productsArray = [];
            }
            const productIndex = productsArray.findIndex(item => 
                item.productId === product.id && item.productDetailId === productDetailId
            );
            if (productIndex > -1) {
                productsArray.splice(productIndex, 1); // Remove the product from the array
                this.cookieService.set(LOCAL_STORAGE.WISH_LIST, JSON.stringify(productsArray), { path: '/' });
                this.productsFromWishList = this.productsFromWishList.filter((item: any) =>
                    productsArray.some((prod: any) => 
                        prod.productId === item.id && prod.productDetailId === item.productDetailId
                    )
                );
                this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                this.showSuccessMessage = true;
                setTimeout(() => this.showSuccessMessage = false, 2000);
            } else {
                return;
            }
        } else {
            const cmsItem = this.wishListCmsData.find(
                (item: any) => item.productId === product.id && item.productDetailId === productDetailId && item.userId === this.userId
            );
    
            if (cmsItem) {
                this.cmsService.getWishListEntry(product.id, this.userId).subscribe({
                    next: (res: any) => {
                        const entry = res.data.find((entry: any) => entry.attributes.productDetailId === productDetailId); // Find specific productDetailId
                        if (entry) {
                            const entryId = entry.id;
                            this.cmsService.removeProductFromWishListById(entryId).subscribe({
                                next: () => {
                                    this.wishListCmsData = this.wishListCmsData.filter(
                                        (item: any) => !(item.productId === product.id && item.productDetailId === productDetailId)
                                    );
                                    this.productsFromWishList = this.productsFromWishList.filter((item: any) =>
                                        this.wishListCmsData.some((prod: any) =>
                                            prod.productId === item.id && prod.productDetailId === item.productDetailId
                                        )
                                    );
                                    this.getWishListData();
                                    this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                                    this.showSuccessMessage = true;
                                    setTimeout(() => this.showSuccessMessage = false, 2000);
                                }
                            });
                        } else {
                            return;
                        }
                    }
                });
            } else {
                return;
            }
        }
    }
    productModal(productDetailModal: any, currentProduct: any) {
        this.currentProduct = currentProduct
        this.currentProductImages = currentProduct.image
        this.currentProductDetail = currentProduct.productDetails[0];
        this.modalService.open(productDetailModal, { size: 'xl', centered: true });
        const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);

        if (availableDetail) {
            this.currentProductDetail = availableDetail;
        } else {
            return;
        }

    }

    isModalOpen(): boolean {
    return this.modalService.hasOpenModals();
    }

    selectProductDetail(detail: any) {
        if (detail.qt > 0) {
            this.currentProductDetail = detail;
        } else {
            const nextAvailableDetail = this.currentProduct.productDetails.find((d: any) => d.qt > 0);
            if (nextAvailableDetail) {
                this.currentProductDetail = nextAvailableDetail;
            } else {
                return;
            }
        }
    }

    addToCart(product: any) {
        this.currentProduct = product;
        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }
        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
        this.showSuccessMessage = true;
        this.cartService.addToCart(product, this.currentProductDetail, this.userId) 
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);
    }

    shareOnFacebook(product: any) {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(shareUrl, '_blank');
    }

    shareOnWhatsApp(product: any) {

        const checkOutThisPost = this.translateService.instant('product-details.CHECK-OUT-THE-PRODUCT')
        const message = `${checkOutThisPost} ${this.currentLanguage == 'en' ? this.currentProduct.name_en : this.currentLanguage == 'sq' ? this.currentProduct.name_sq : this.currentProduct.name_mk}`;
        const url = encodeURIComponent(window.location.href);
        const whatsappLink = `https://api.whatsapp.com://send?text=${message} ${url}`;
        window.location.href = whatsappLink;
    }

    shareOnTwitter(product: any) {
        const postTitle = this.currentLanguage === 'en' ? this.currentProduct.name_en :
            this.currentLanguage === 'sq' ? this.currentProduct.name_sq :
                this.currentProduct.name_mk;

        const tweetText = encodeURIComponent(postTitle);
        const tweetUrl = encodeURIComponent(window.location.href);

        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;

        window.open(twitterShareUrl, '_blank', 'width=600,height=400');
    }
    async moveAllToBag() {
        this.showSuccessMessage = false;
        this.showWarningMessage = false;
        if (this.productsFromWishList && this.productsFromWishList.length > 0) {
            if (this.userId === '') {
                this.moveWishlistToLocalProducts();
                return;
            } else {
                try {
                    const existingCartResponse = await this.cmsService.getCartByUser(this.userId).toPromise();
                    const existingCart = existingCartResponse?.data || [];
                    const existingOrderMap = this.createOrderMap(existingCart);
                    const newCartItems = [];
                    const updateCartItems = [];
                    const updateCart: any[] = [];

                    for (let item of this.productsFromWishList) {
                        const productDetail = item.productDetails[0];
                        const newCartKey = `${item.id}_${productDetail.id}`;
    
                        if (existingOrderMap[newCartKey]) {
                            const existingItem = existingOrderMap[newCartKey];
                            const updatedQuantity = existingItem.quantity + 1;
                            updateCartItems.push({
                                id: existingItem.id,
                                quantity: updatedQuantity
                            });
                            updateCart.push({
                                id: existingItem.id,
                                productId: existingItem.productId,
                                productDetailId: existingItem.productDetailId,
                                quantity: updatedQuantity
                            });
    
                        } else if (productDetail.qt > 0) {
                            newCartItems.push({
                                productId: item.id,
                                productDetailId: productDetail.id,
                                quantity: 1,
                                userId: this.userId
                            });
                        }
                    }

                    if (newCartItems.length > 0) {
                        await Promise.all(newCartItems.map(cartItem => this.cmsService.setCartList(cartItem).toPromise()));
                    }
    
                    if (updateCartItems.length > 0) {
                        await Promise.all(updateCartItems.map(cartItem => 
                            this.cmsService.updateCartProduct(cartItem).toPromise()
                        ));
                    }
    
                    const updatedCartItems = [
                        ...Object.values(existingOrderMap).map(item => {
                            const update = updateCart.find(u => u.productId === item.productId && u.productDetailId === item.productDetailId);
                            return update ? { ...item, quantity: update.quantity } : item;
                        }),
                        ...newCartItems.map(item => ({
                            productId: item.productId,
                            productDetailId: item.productDetailId,
                            quantity: item.quantity
                        }))
                    ];
                    this.handleFetchedProducts(updatedCartItems);
    
                    this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
                    this.showSuccessMessage = true;
    
                } catch (error) {
                }
            }
    
        } else {
            this.successMessage = this.translateService.instant("WISHLIST.EMPTY_WISHLIST");
            this.showWarningMessage = true;
        }
        setTimeout(() => {
            this.showSuccessMessage = false;
            this.showWarningMessage = false;
        }, 2000);
    }
 
    private createOrderMap(existingCart: any[]) {
        const orderMap: { [key: string]: any } = {};
        existingCart.forEach((item: any) => {
            const productId = item.attributes.productId;
            const productDetailId = item.attributes.productDetailId;
            const key = `${productId}_${productDetailId}`;
            orderMap[key] = {
                id: item.id,
                quantity: item.attributes.quantity,
                productId: productId,
                productDetailId: productDetailId
            };
        });
        
        return orderMap;
    }

    moveWishlistToLocalProducts() {
        let wishList = JSON.parse(this.cookieService.get(LOCAL_STORAGE.WISH_LIST) || '[]');
        let productsArray = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
    
        wishList.forEach((wishItem: any) => {
            const indexProduct = productsArray.findIndex((p: any) =>
                p.productId === wishItem.productId && p.productDetailId === wishItem.productDetailId
            );
    
            if (indexProduct !== -1) {
                productsArray[indexProduct].quantity += 1;
            } else {
                productsArray.push({
                    productId: wishItem.productId,
                    productDetailId: wishItem.productDetailId,
                    quantity: 1,
                    image: wishItem.image,
                });
            }
            this.handleFetchedProducts(productsArray)
        });
        this.cookieService.set(LOCAL_STORAGE.PRODUCTS, JSON.stringify(productsArray), { path: '/' });

        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
        this.showSuccessMessage = true;
    
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);
    }

    handleFetchedProducts(productsArray: any) {
            if (this.productsFromConome.length > 0) {
                const updatedProductsArray = productsArray.map((cartItem: any) => {
                    const matchingProduct = this.productsFromConome.find((p: any) => p.id === cartItem.productId);
                    return matchingProduct ? { ...cartItem, ...matchingProduct } : cartItem;
                });
                this.cartService.getNumberOfProducts(updatedProductsArray);
                this.cartService.updateCartProducts(updatedProductsArray);
            }
    }

    productDetails(id: any) {
        this.router.navigate(['product-details', id])
    }
}
