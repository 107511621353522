<div class="container">

<section class="tab-content py-sm-5 margin-2-top">
  <div class="d-flex justify-content-between header">
    <div><h2>{{headersFromCms?.firstHeader?.headerText}} ({{productsFromWishList ? productsFromWishList.length : 0}})</h2></div>
    <div>
      <div class="second-button pointer" (click)="moveAllToBag()">
        {{headersFromCms?.firstHeader?.button?.text}}
      </div>
    </div>
  </div>
  <div class="grid-container pt-3 pt-sm-0 px-1">
    <div *ngFor="let item of productsFromWishList;let i = index;" >
      <div class="card product-card pb-2">
        <div class="product-image-container">
          <p role="button" class="d-block" data-bs-toggle="modal" (click)="productDetails(item.id)">
            <img class="card-img-top"
              [src]="item.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
              alt="Image">

          <div class="d-flex justify-content-between">
            <div class="discount absolute"><span class="discount-percentage"></span></div>
            <div class="icons-container absolute">
              <div class="wishlist" (click)="removeFromWishList(item, item.productDetailId)">
                <svg fill="#9292A3" height="22px" width="22px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 465.311 465.311" xml:space="preserve">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="15.820573999999999"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path d="M372.811,51.002h-59.908V36.566C312.902,16.404,296.499,0,276.335,0h-87.356c-20.163,0-36.567,16.404-36.567,36.566v14.436 H92.5c-20.726,0-37.587,16.861-37.587,37.587v38.91c0,8.284,6.716,15,15,15h7.728v307.812c0,8.284,6.716,15,15,15H372.67 c8.284,0,15-6.716,15-15V142.499h7.728c8.284,0,15-6.716,15-15v-38.91C410.397,67.863,393.536,51.002,372.811,51.002z M182.412,36.566c0-3.621,2.946-6.566,6.567-6.566h87.356c3.621,0,6.567,2.946,6.567,6.566v14.436h-100.49V36.566z M84.914,88.589 c0-4.184,3.403-7.587,7.587-7.587h280.31c4.184,0,7.587,3.403,7.587,7.587v23.91H84.914V88.589z M357.67,435.311H107.641V142.499 H357.67V435.311z"></path>
                      <path d="M137.41,413.485c5.523,0,10-4.477,10-10V166.497c0-5.523-4.477-10-10-10s-10,4.477-10,10v236.988 C127.41,409.008,131.887,413.485,137.41,413.485z"></path>
                      <path d="M200.907,413.485c5.523,0,10-4.477,10-10V166.497c0-5.523-4.477-10-10-10s-10,4.477-10,10v236.988 C190.907,409.008,195.384,413.485,200.907,413.485z"></path>
                      <path d="M264.404,413.485c5.523,0,10-4.477,10-10V166.497c0-5.523-4.477-10-10-10s-10,4.477-10,10v236.988 C254.404,409.008,258.881,413.485,264.404,413.485z"></path>
                      <path d="M327.901,413.485c5.523,0,10-4.477,10-10V166.497c0-5.523-4.477-10-10-10s-10,4.477-10,10v236.988 C317.901,409.008,322.378,413.485,327.901,413.485z"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="see-product" (click)="productDetails(item.id)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                  </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-2">
          <h3 class="product-title fs-md">
            <p (click)="productDetails(item.id)" data-bs-toggle="modal">
              {{(currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' |translate}} &nbsp;<span class="detail-name"> {{item.productDetails[0]?.productName ? item.productDetails[0].productName: ''}} </span>
            </p>
          </h3>
          <div class="d-flex align-items-center justify-content-start">
            <div class="product-price-container"><span class="product-price">{{item.productDetails[0]?.sellingPriceWithVat}}
                {{"currency.CURRENCY_VALUE"| translate}}</span></div>
          </div>
        </div>
        <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0" (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item) : '')">{{'products.ADD-TO-CART'|translate}}</div>
      </div>
    </div>
  </div>
</section> 

<section class="margin-3-top margin-3-bottom">
  <div class="d-flex justify-content-between header">
    <div class="d-flex align-items-center">
      <div class="orange-box"></div>
      <h2 class="text-header">{{headersFromCms?.secondHeader?.headerText}}</h2>
    </div>
    <div>
      <div class="second-button pointer" [routerLink]="headersFromCms?.secondHeader?.button?.link">
        {{headersFromCms?.secondHeader?.button?.text}}
      </div>
    </div>
  </div>
  <div class="grid-container pt-3 pt-sm-0 px-1">
    <div *ngFor="let item of productsJustForYou;let i = index;" >
      <div class="card product-card pb-2">
        <div class="product-image-container">
          <p role="button" class="d-block" data-bs-toggle="modal" (click)="productDetails(item.id)">
            <img class="card-img-top"
              [src]="item.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
              alt="Image">

          <div class="d-flex justify-content-between">
            <div class="discount absolute"><span class="discount-percentage"></span></div>
            <div class="icons-container absolute">
              <div class="see-product" (click)="productDetails(item.id)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                  </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-2">
          <h3 class="product-title fs-md">
            <p (click)="productDetails(item.id)" data-bs-toggle="modal">
              {{(currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' |translate}}
            </p>
          </h3>
          <div class="d-flex align-items-center justify-content-start">
            <div class="product-price-container"><span class="product-price">{{item.productDetails[0]?.sellingPriceWithVat}}
                {{"currency.CURRENCY_VALUE"| translate}}</span></div>
          </div>
        </div>
        <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0" (click)="item.productDetails.length > 1 ? productModal(hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart(item) : '')">{{'products.ADD-TO-CART'|translate}}</div>
      </div>
    </div>
  </div>
</section>

</div>

<ng-template #hasProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute"
    type="button"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
  </button>
    <div class="px-lg-3">
        <div class="has-product-details">
          <div class="pb-3 mt-2">
            <div class="mb-3">
              <span class="h3 fw-normal text-accent me-1">
                {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            </div>
            <div class="mb-3 d-flex align-items-center">
              <button [class.disabled]="currentProductDetail.qt <= 0" (click)="addToCart(currentProduct)" class="btn btn-primary btn-shadow d-block w-100">
                <i class="ci-cart fs-lg me-2"></i>
                {{'products.ADD-TO-CART'|translate}}
              </button>
            </div>
          </div>
          <div class="mt-4">
            <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
            <div class="product-details-names pointer">
              <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails" (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0" [class.selected]="detail === currentProductDetail">
                {{ detail.productName }}
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>

<div class="success-message message-container" *ngIf="showSuccessMessage">
  <div class="success-message">
    {{ successMessage }}
  </div>
</div>
<div class="warning-message message-container" *ngIf="showWarningMessage">
  <div class="warning-message">
    {{ successMessage }}
  </div>
</div>
