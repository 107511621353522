import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataSharedService {

    constructor() { }
    private isUserLoggedIn = new Subject<boolean>();
    isUserLoggedIn$ = this.isUserLoggedIn.asObservable();
    private categoryId: number = -1;
    
    setLoginStatus(status: boolean) {
        this.isUserLoggedIn.next(status);
    }

    setCategoryId(id: number): void {
        this.categoryId = id;
    }

    getCategoryId(): number {
        return this.categoryId;
    }

}
