<div class="container signup-content">
    <div class="row d-flex align-items-center">
        <div class="col-lg-8 col-md-6 col-sm-12 col-12">
          <div class="image-container">
            <img src="../../../assets/images/lozztoys-logo.png" alt="lozztoys-logo">
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <h2>Create an account</h2>
            <p class="margin-0">Enter your details below</p>

            <form (ngSubmit)="onSubmit()" [formGroup]="signupForm" class="margin-2-top">
              <div class="input-container">
                  <input 
                      placeholder="Name" 
                      class="input-field" 
                      type="text" 
                      formControlName="name">
                  <label for="input-field" class="input-label">Name</label>
                  <span 
                      class="input-highlight" 
                      [ngClass]="{
                          'input-error': signupform.name.touched && signupform.name.invalid,
                          'input-success': signupform.name.touched && signupform.name.valid
                      }"></span>
              </div>
          
              <div class="input-container margin-1-top">
                  <input 
                      placeholder="Email" 
                      class="input-field" 
                      type="text" 
                      formControlName="email">
                  <label for="input-field" class="input-label">Email</label>
                  <span 
                      class="input-highlight" 
                      [ngClass]="{
                          'input-error': signupform.email.touched && signupform.email.invalid,
                          'input-success': signupform.email.touched && signupform.email.valid
                      }"></span>
              </div>
          
              <div class="input-container margin-1-top">
                  <input 
                      placeholder="Password" 
                      class="input-field" 
                      type="password" 
                      formControlName="password">
                  <label for="input-field" class="input-label">Password</label>
                  <span 
                      class="input-highlight" 
                      [ngClass]="{
                          'input-error': signupform.password.touched && signupform.password.invalid,
                          'input-success': signupform.password.touched && signupform.password.valid
                      }"></span>
              </div>
          
              <button [disabled]="!isFormValid" class="create-account-button d-block w-100" type="submit">Create Account</button>
          
              <div class="login d-flex justify-content-center mt-3">
                  <p class="margin-0">Already have account?  <a href="/login">Log in</a></p>
              </div>
          </form>
        </div>
    </div>
</div>
