/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/modules/interfaces';
import {environment} from "../../../environments/environment";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private translateService:TranslateService) {}

  products: Product[] = [];
  
  getProducts() {
    return this.http.get<{data:any[],meta:object}>(`${environment.backendUrl}/api/products?sort=name_${this.currentLanguage}&populate=*`)
  }

  getRandomProducts() {
    return this.http.get<{ data: any[] }>(`${environment.backendUrl}/api/product/random?populate=*`);
  }
  

  getProductById(id:number) {
    return this.http.get<{data:any,meta:object}>(`${environment.backendUrl}/api/products/${id}?populate=*`);
  }

  updateProductById(product:Product,newQuantity:number) {
    return this.http.put(`${environment.backendUrl}/api/products/${product.id}`,{data:{quantity_available: newQuantity}});    
  }

  
  get currentLanguage(){
    return this.translateService.currentLang
}

}
