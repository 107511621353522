import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, AfterViewInit, PLATFORM_ID, Inject, TransferState, makeStateKey, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageArray } from 'src/app/modules/interfaces';
import { ProductsService } from '../../services/products/products.service';
import { LOCAL_STORAGE } from '../../modules/constants';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { SeoService } from 'src/app/services/seoService/seo.service';
import { Apollo } from 'apollo-angular';
import { switchMap, take } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { forkJoin } from 'rxjs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { DataSharedService } from 'src/app/services/data-shared.service';

const DEFAULT_IMAGE = environment.backendUrl + "/uploads/default_image_dd0d79f0e4.png"
const COMPANY_INFO_KEY = makeStateKey<any>('companyInfo');
const COMPANY_ID = makeStateKey<any>('companyId');
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewChecked, AfterViewInit {
    shownProducts: any = [];
    shownPosts: any = [];
    currentProductImages!: ImageArray[];
    currentProduct!: any;
    environment: any;
    hasToken = false;
    showSuccessMessage = false;
    showWarningMessage = false;
    successMessage = '';
    content: any;
    companyInfo: any;
    companyId = 0;
    selectedCategory = -1;
    filteredProducts: any[] = [];
    carousel: any[] = [];
    days = 3;
    hours = 10;
    minutes = 16;
    seconds = 10;
    currentProductDetail: any;
    cmsUrl = environment.backendUrl;
    currentLanguage = 'sq';
    homeData: any;
    serviceHighlights: any;
    topProducts: any[] = [];
    responseTopProducts: any
    topCategories: any[] = [];
    
    countdownData: Record<string, any> = {}; // To store countdown data dynamically
    promotionSectionData: any;
    flashSalesData: any;
    private countdownIntervals: { [key: string]: any } = {};
    promotionCountdown: any = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    
      flashSalesCountdown: any = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };

    userId: string = '';
    wishListData: any;
    productsFromConome: any[] = [];
    categoriesFromConome:any[] = [];
    sortedProducts:any[] = [];
    ourProducts: any[] =[];
    conomeUrl = environment.conomeUrl
    slidesPerView = 4; 
    isBuyNow: boolean = false;
    modalRef: NgbModalRef | undefined;

    @ViewChild('swiper', { static: true }) swiperContainer: any;
    public config: SwiperOptions = {}

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private modalService: NgbModal,
        public cartService: CartService,
        private seo: SeoService,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private conomeService: ConomeECommerceService,
        private transferState: TransferState,
        private cmsService: LozztoysCmsService,
        private userProfileService: UserProfileService,
        private sharedService: DataSharedService,
        
    ) {
        this.environment = environment
    }

    ngAfterViewInit() {
        new Swiper('.swiper-products', {
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                220: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                785: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1100: {
                    slidesPerView: 4,
                    spaceBetween: 20
                }
            }
        });

        new Swiper('.swiper-categories', {
            navigation: {
                prevEl: '.swiper-button-prev-categories',
                nextEl: '.swiper-button-next-categories'
            },
            breakpoints: {
                220: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                380: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                630: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                810: {
                    slidesPerView: 5,
                    spaceBetween: 10
                },
                1100: {
                    slidesPerView: 6,
                    spaceBetween: 10
                },
                1500: {
                    slidesPerView: 7,
                    spaceBetween: 10
                }
            },
        });
    }

    ngOnInit() {
        localStorage.removeItem('couponCode')
        this.updateSwiperConfig();
        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });
        this.getCompanyInfo();

        this.getTopPicks();
        /* this.getTopProductsFromCMS(); */
        this.getCategoriesFromCMS();
        this.getCarouselFromCMS();
        this.getHomeFromCMS();
        this.getFromWishList();
        this.translateService.onLangChange.subscribe((res) => {
            this.currentLanguage = res.lang;
            this.getCarouselFromCMS();
            this.getHomeFromCMS();
        });
        
        this.seo.getAndUpdateSeo();
        this.hasToken = this.cookieService.get(LOCAL_STORAGE.TOKEN) ? true : false;
        /*  this.hasToken = localStorage.getItem(LOCAL_STORAGE.TOKEN) ? true : false; */
    }

    updateSwiperConfig() {
        if (window.innerWidth < 480) {
            this.slidesPerView = 1;
        } else if (window.innerWidth < 785) {
            this.slidesPerView = 2;
        } else if (window.innerWidth < 1100) {
            this.slidesPerView = 3;
        } else {
            this.slidesPerView = 4;
        }

        this.config = {
            navigation: {
                prevEl: '.button-prev',
                nextEl: '.button-next'
            },
            slidesPerView: this.slidesPerView,
            slidesPerGroup: this.slidesPerView,
            spaceBetween: 20,
            breakpoints: {
                220: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    }
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    }
                },
                785: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    }
                },
                1100: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    }
                }
            },
            injectStyles: [],
        };
    }
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.updateSwiperConfig();
    }

    ngAfterViewChecked() {
        this.hasToken = this.cookieService.get(LOCAL_STORAGE.TOKEN) ? true : false;
        /* this.hasToken = localStorage.getItem(LOCAL_STORAGE?.TOKEN) ? true : false; */
    }

    get defaultImage() {
        return DEFAULT_IMAGE;
    }

    goToUrl(url: any) {
        const urlPath = new URL(url)?.pathname;
        this.router.navigateByUrl(urlPath);
    }


    goToProducts() {
        this.router.navigateByUrl('/products');
    }

    productModal(productDetailModal: any, currentProduct: any) {
        this.currentProduct = currentProduct
        this.currentProductImages = currentProduct.image
        this.currentProductDetail = currentProduct.productDetails[0];
        this.modalRef = this.modalService.open(productDetailModal, { size: 'xl', centered: true });
        const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);

        if (availableDetail) {
            this.currentProductDetail = availableDetail;
        } else {
            return;
        }

    }

    isModalOpen(): boolean {
    return this.modalService.hasOpenModals();
    }

    selectProductDetail(detail: any) {
        if (detail.qt > 0) {
            this.currentProductDetail = detail;
        } else {
            const nextAvailableDetail = this.currentProduct.productDetails.find((d: any) => d.qt > 0);
            if (nextAvailableDetail) {
                this.currentProductDetail = nextAvailableDetail;
            } else {
               return;
            }
        }
    }

    shareOnFacebook(product: any) {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(shareUrl, '_blank');
    }


    shareOnWhatsApp(product: any) {

        const checkOutThisPost = this.translateService.instant('product-details.CHECK-OUT-THE-PRODUCT')
        const message = `${checkOutThisPost} ${this.currentLanguage == 'en' ? this.currentProduct.name_en : this.currentLanguage == 'sq' ? this.currentProduct.name_sq : this.currentProduct.name_mk}`;
        const url = encodeURIComponent(window.location.href);
        const whatsappLink = `https://api.whatsapp.com://send?text=${message} ${url}`;
        window.location.href = whatsappLink;
    }

    shareOnTwitter(product: any) {
        const postTitle = this.currentLanguage === 'en' ? this.currentProduct.name_en :
            this.currentLanguage === 'sq' ? this.currentProduct.name_sq :
                this.currentProduct.name_mk;

        const tweetText = encodeURIComponent(postTitle);
        const tweetUrl = encodeURIComponent(window.location.href);

        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;

        window.open(twitterShareUrl, '_blank', 'width=600,height=400');
    }

    filterImg(e: any, image: any) {
        document.querySelectorAll('.product-gallery-thumblist a').forEach(element => {
            element.classList.remove('active')
        });
        const img: HTMLImageElement = (document.querySelector('#first img') as HTMLImageElement);
        img.src = image;
        e.target.closest('a').classList.add('active');
    }

    extractText(content: string | null | undefined): string {
        if (!content) {
            return '';
        }
        const textSegments: string[] = [];

        const regex = /!\[.*?\]\((.*?)\)/g;
        let lastIndex = 0;
        let match = regex.exec(content);

        while (match) {
            const beforeText = content.substring(lastIndex, match.index);

            textSegments.push(beforeText);

            lastIndex = match.index + match[0].length;
            match = regex.exec(content);
        }

        // Add the remaining text after the last image
        textSegments.push(content.substring(lastIndex));

        const textContent = textSegments.join(''); // Join the text segments to form the text content

        return textContent;
    }

    // checkIfThereAreMoreProducts(product: any) {
    //         const products = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]')
    //         // Get the specific product by id
    //         const prod = this.filteredProducts.find((prod: any) => prod.id === product.id)
    //         if (!prod) {
    //             return product.qt === 0
    //         } else {
    //             return prod && product.qt && prod?.qt >= product.qt ? true : false
    //         }
    // }

    addToCart(product: any){
        this.isBuyNow = false;
        this.currentProduct = product;

        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }

        this.showSuccessMessage = true;
        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART")
        this.cartService.addToCart(product, this.currentProductDetail, this.userId)
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);

    }
    
    getCompanyInfo() {
        this.conomeService.products$.subscribe(products => {
            this.productsFromConome = products;
            if (this.productsFromConome.length > 0){
                this.sortedProducts = this.productsFromConome.sort((a:any, b:any) => {
                    return new Date(b.created).getTime() - new Date(a.created).getTime();
                });
                this.sortedProducts = this.sortedProducts.slice(0, 4)
                this.ourProducts = this.productsFromConome.slice(0, 24)
                this.filterProductsByCategory();
                this.displayTopProducts();
            }
        });
        this.conomeService.categories$.subscribe(categories => {
            this.categoriesFromConome = categories;
            // this.getCategoriesFromCMS();
          });
    }

    columnNameByLanguage(category:any){
        if(this.currentLanguage === 'sq'){}
        const lang = "name"+this.currentLanguage.toUpperCase();
        return category[lang];
    }

    filterProductsByCategory(): void {
        const companyId = Number(this.cookieService.get('companyID'));
        const page = 1;     
        const pageSize = 20;  
    
        // If "All" is selected, fetch all products (categoryId = 0)
        const categoryId = this.selectedCategory === -1 ? 0 : this.selectedCategory;
    
        this.conomeService.getProductsByCategoryId(companyId, categoryId, page, pageSize).subscribe(
          (response: any) => {
            this.filteredProducts = response;
          },
          (error: any) => {
            console.error('Error fetching products', error);
          }
        );
      }


    getCarouselFromCMS(){
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getCarousel(populateAndLocale).subscribe({
            next: (res) => {
                this.carousel = res.data.attributes.carousel.map((item:any) => {
                    return {
                        title: item.title,
                        paragraph: item.paragraph,
                        carouselButton: item.carouselButton,
                        redirectLink: item.redirectLink,
                        carousel_image: this.cmsUrl + item.carousel_image.data.attributes.url,
                        backgroundColor: item.backgroundColor
                    }
                })
            },
            error: (err) => {
                console.error(err);
            }
        })    
    }

    getHomeFromCMS() {
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getHome(populateAndLocale).subscribe({
            next: (res) => {
                this.homeData = res.data.attributes;
                this.serviceHighlights = res.data.attributes.textAndImage;
                this.promotionSectionData = this.homeData.promotionSection
                this.flashSalesData = this.homeData.flashSalesSection

                this.startCountdown(this.promotionSectionData.countDown.dateTimeTo, 'promotionCountdown');
                this.startCountdown(this.flashSalesData.countDown.dateTimeTo, 'flashSalesCountdown');
      
            },
            error: (err) => {
                console.error(err);
            }
        })    
    }
   
    
    startCountdown(dateTimeTo: string, key: 'promotionCountdown' | 'flashSalesCountdown') {
        const countDownDate = new Date(dateTimeTo).getTime();
    
        this[key] = { days: '00', hours: '00', minutes: '00', seconds: '00' };
    
        if (this.countdownIntervals[key]) {
          clearInterval(this.countdownIntervals[key]);
        }
    
        this.countdownIntervals[key] = setInterval(() => {
          const now = new Date().getTime();
          const distance = countDownDate - now;
    
          if (distance < 0) {
            clearInterval(this.countdownIntervals[key]);
            this[key] = { days: '00', hours: '00', minutes: '00', seconds: '00' };
            return;
          }
    
          const newCountdown = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0'),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0'),
            seconds: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0'),
          };
    
          if (this[key].days !== newCountdown.days) this.animateCountdown(key, 'days', newCountdown.days);
          if (this[key].hours !== newCountdown.hours) this.animateCountdown(key, 'hours', newCountdown.hours);
          if (this[key].minutes !== newCountdown.minutes) this.animateCountdown(key, 'minutes', newCountdown.minutes);
          if (this[key].seconds !== newCountdown.seconds) this.animateCountdown(key, 'seconds', newCountdown.seconds);
        }, 1000);
      }
    
      animateCountdown(key: 'promotionCountdown' | 'flashSalesCountdown', timeUnit: 'days' | 'hours' | 'minutes' | 'seconds', newValue: string) {
        // Ensure this code only runs in the browser
        if (isPlatformBrowser(this.platformId)) {
          (this[key] as CountdownData)[timeUnit] = newValue;
      
          setTimeout(() => {
            const selector = key === 'promotionCountdown'
              ? `.countdown-banner .${timeUnit}`
              : `.countdown .${timeUnit}`;
            const element = document.querySelector(selector) as HTMLElement;
      
            if (element) {
              element.classList.remove('flip'); 
              void element.offsetWidth;
              element.classList.add('flip');
            } else {
              console.error('Element not found for selector:', selector);
            }
          }, 10);
        }
      }
      removeAllFlipAnimations() {
        if (isPlatformBrowser(this.platformId)) {
            const elements = document.querySelectorAll('.countdown .time-value, .countdown-banner .time-value-banner');
            elements.forEach((element) => {
            element.classList.remove('flip');
            });
        }
      }

      ngOnDestroy() {
        Object.values(this.countdownIntervals).forEach(clearInterval);
        this.removeAllFlipAnimations();
        this.modalService.dismissAll
      }

    redirectTo(link: string) {
        if (link) {
            if (link.startsWith('http://') || link.startsWith('https://')) {
                window.location.href = link;
            } else {
                this.router.navigate([link]);
            }
        }
    }

    redirectToProducts(link: string, category: any){
        this.redirectTo(link)
         this.sharedService.setCategoryId(category.id);
    }

    addToWishList(product: any) {
        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }

        let productsArray: any[] = [];
        this.showSuccessMessage = false;
        this.showWarningMessage = false;
    
        const productDetailId = this.currentProductDetail.id;
    
        if (this.userId === '') {
            const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            if (storedWishlist) {
                productsArray = JSON.parse(storedWishlist);
                if (!Array.isArray(productsArray)) {
                    productsArray = [];
                }
            }
            const productExists = productsArray.some(
                item => item.productId === product.id && item.productDetailId === productDetailId
            );
    
            if (productExists) {
                this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
                this.showWarningMessage = true;
            } else {
                productsArray.push({ productId: product.id, productDetailId: productDetailId, image: product.image });
                this.cookieService.set(LOCAL_STORAGE.WISH_LIST, JSON.stringify(productsArray), { path: '/' });
                this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                this.showSuccessMessage = true;
            }
        } else {
            const wishlistData = {
                productId: product.id,
                productDetailId: productDetailId,
                userId: this.userId,
                image: product.image
            };
    
            const productExists = this.wishListData.some(
                (item: any) => item.productId === product.id && item.productDetailId === productDetailId
            );
    
            if (productExists) {
                this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
                this.showWarningMessage = true;
            } else {
                this.wishListData = [...this.wishListData, wishlistData];
                productsArray.push(wishlistData);
    
                this.cmsService.setWishList(wishlistData).subscribe(
                    response => {
                    },
                );
                this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                this.showSuccessMessage = true;
            }
        }
        this.getFromWishList();
        setTimeout(() => {
            this.showSuccessMessage = false;
            this.showWarningMessage = false;
        }, 2000);
    }

    getFromWishList() {
        if (this.userId === '') {
            const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            this.wishListData = wishListCmsData ? JSON.parse(wishListCmsData) : []
        }
        else {
            this.cmsService.getWishList(this.userId).subscribe(res => {
                this.wishListData = res.data
                    .map((item: any) => {
                        return {
                            productId: item.attributes.productId,
                            productDetailId: item.attributes.productDetailId,
                            userId: item.attributes.userId
                        };
                    });
            });
        }

    }

  isInWishList(item: any): boolean {
    let result = this.wishListData?.some((wish: any) => wish.productId === item.id)
    return result;
  }

    getCategoriesFromCMS(){
        this.cmsService.getCategoriesFromCMS().subscribe({
            next: (res) => {
                this.topCategories = res.data
            },
            error: (err) => {
                console.error(err);
            }

        })
    }

    topPicks: any[] = [];
    getTopPicks() {
        this.cmsService.getTopPicksFromCMS().subscribe({
            next: (res) => {
                this.topPicks = res.data.flatMap((item: any) => item.attributes.products.data); // Extract products
                this.displayTopProducts();
            },
            error: (err) => {
                console.error(err);
            }
        });
    }
    getTopProductsFromCMS(){
        this.cmsService.getTopProductsFromCMS().subscribe({
            next: (res) => {
                this.responseTopProducts = res.data
                this.displayTopProducts(); 
            },
            error: (err) => {
                console.error(err);
            }
        })
    }

    displayTopProducts() {
        if (this.productsFromConome.length > 0) {
            this.topProducts = this.productsFromConome.filter(product => 
                this.topPicks?.some((item: any) => item.attributes.productID === product.id)
            );
        }
    }

    productDetails(id: any) {
        this.router.navigate(['product-details', id])
    }

    buyNow(product: any, productDetail: any) {
        const newCartItem = {
          ...product,
          productDetails: [productDetail],
          quantity: 1,
        };
        this.cartService.setBuyNowProduct(newCartItem);
        this.router.navigate(['/checkout'])
      }
      
      buyOrAddToCart() {
        if (this.isBuyNow) {
          this.buyNow(this.currentProduct, this.currentProductDetail);
        if (this.modalRef) {
            this.modalRef.dismiss();
        }

        } else {
          this.addToCart(this.currentProduct);
        }
      }
}

interface CountdownData {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  }