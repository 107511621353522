<div class="container">
    <app-banner  [breadcrumbItems]="breadCrumbItems"></app-banner>
<div class="user-profile-container">
    <div class="sidebar">
      <ul>
        <li routerLinkActive="active"><a routerLink="my-profile">My Profile</a></li>
        <li routerLinkActive="active"><a routerLink="my-orders">My Orders</a></li>
        <li routerLinkActive="active"><a routerLink="wishlist">Wishlist</a></li>
      </ul>
    </div>
  
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>