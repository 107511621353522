import { Component } from '@angular/core';

@Component({
    selector: 'app-my-returns',
    standalone: false,
    templateUrl: './my-returns.component.html',
    styleUrl: './my-returns.component.scss'
})
export class MyReturnsComponent {
    orders: any[] = [];

    ngOnInit(): void {
        this.loadDummyOrders();
    }

    loadDummyOrders() {
        this.orders = [
            {
                orderNumber: '123456',
                datePurchased: '2024-09-01',
                status: 'Shipped',
                paymentStatus: 'Paid',
                total: 120.99
            },
            {
                orderNumber: '234567',
                datePurchased: '2024-09-10',
                status: 'Processing',
                paymentStatus: 'Pending',
                total: 89.50
            },
            {
                orderNumber: '345678',
                datePurchased: '2024-09-15',
                status: 'Delivered',
                paymentStatus: 'Paid',
                total: 199.99
            },
            {
                orderNumber: '456789',
                datePurchased: '2024-09-20',
                status: 'Canceled',
                paymentStatus: 'Refunded',
                total: 59.00
            }
        ];
    }

    getStatusClass(status: string): string {
        switch (status.toLowerCase()) {
            case 'shipped':
                return 'status-shipped';
            case 'processing':
                return 'status-processing';
            case 'canceled':
                return 'status-cancelled';
            case 'refunded':
                return 'status-refunded';
            default:
                return 'status-default';
        }
    }
}
