import { Component, HostListener, OnInit } from '@angular/core';
import { ImageArray, Product } from '../../modules/interfaces';
import { ProductsService } from '../../services/products/products.service';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import Swal from 'sweetalert2';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { forkJoin, switchMap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LozztoysCmsService } from '../../services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from '../../services/user/user-profile.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {

    constructor(
        private productsService: ProductsService,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private categoriesService: CategoriesService,
        private cartService: CartService,
        private emailService: EmailServiceService,
        private conomeService: ConomeECommerceService,
        private cookieService: CookieService,
        private cmsService: LozztoysCmsService,
        private userProfileService: UserProfileService,
        private sharedService: DataSharedService,
        private router: Router


    ) {
        this.environment = environment;
    }
    showSuccessMessage = false;
    showWarningMessage = false
    successMessage = '';
    indexPage = 0;
    itemsPerPage = 20;
    products: Product[] = [];
    allProducts: Product[] = [];
    allProductsFiltered: Product[] = [];
    hasToken = false;
    selectedProductIndex = -1
    currentProductImages!: ImageArray[];
    currentProduct!: any;
    isMobile = false
    searchText = '';
    environment: any;
    barFilled!: number;
    isRecentlyAdded = true;
    maxPriceValue = 0;
    filter: { prices: { min: any, max: any } } = {
        prices: {
            min: "",
            max: "",
        },
    };
    selectedCategory = -1;
    filterCategory = {
        category: '',
    };
    filterPriceForm = new FormGroup({
        min: new FormControl(),
        max: new FormControl()
    });
    breadCrumbItems!: any;
    productsFromConome: any[] = [];
    companyId = 0;
    conomeUrl = environment.conomeUrl
    categoriesFromConome: any[] = [];
    productCategoriesFromConome: any[] = [];
    filteredProducts: any[] = [];
    userId: string = '';
    wishListData: any;
    currentPage: number = 1;
    totalFilteredProductsCount: number = 0;
    page: number = 1;
    pageSize: number = 20;
    showMoreButton: boolean = true;
    previousCategory: number = -1;
    
    ngOnInit(): void {

        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });
        this.sortProducts();
        this.hasToken = this.cookieService.get(LOCAL_STORAGE.TOKEN) ? true : false;
        this.checkDeviceIfOnMobile();
        /* this.getAllProducts(); */
        this.getFromWishList();
        this.getCategoryIdFromHome();
        this.translateBreadcrumbItems(); // Translate initially
        // Subscribe to language changes and update translations
        this.translateService.onLangChange.subscribe(() => {
            this.translateBreadcrumbItems();
            const queryString = window.location.search;
            if (queryString.includes('paymentStatus=1')) {
                const storedEmailDataString = localStorage.getItem("emailData");
                if (storedEmailDataString !== null) {
                    const storedEmailData = JSON.parse(storedEmailDataString);
                    const emailData = {
                        to: storedEmailData.to,
                        subject: storedEmailData.subject,
                        html: storedEmailData.html
                    };
                    this.emailService.sendEmail(emailData).subscribe();
                    localStorage.removeItem("emailData");
                }
                this.showSuccessOrFailMessageAndNavigate(this.translateService.instant("checkout.PAYMENT-SUCCESSFULL"), true);
            } else if (queryString.includes('paymentStatus=0')) {
                localStorage.removeItem("emailData");
                this.showSuccessOrFailMessageAndNavigate(this.translateService.instant("checkout.PAYMENT-UNSUCCESSFULL"), false);
            }
        });
    }
    filterProductsByCategory() {
        this.conomeService.categories$.subscribe(categories => {
            this.categoriesFromConome = categories;
        });
        const companyId = Number(this.cookieService.get('companyID'));
        const categoryId = this.selectedCategory;
    
        if (this.selectedCategory !== this.previousCategory) {
          this.page = 1;
          this.filteredProducts = [];
        }
    
        if (this.selectedCategory === -1) {
          this.conomeService.getAllProducts(companyId, this.page, this.pageSize).subscribe(
            (response: any) => {
              if (this.page === 1) {
                this.allProductsFiltered = response;
                if(this.allProductsFiltered.length === this.pageSize){
                    this.showMoreButton = true;
                }
                this.loadMore(true);
              } else {
                this.allProductsFiltered = [...this.allProductsFiltered, ...response];
                this.loadMore(true);
              }
              this.updateFilteredCount();
              // Hide the "Show More" button if fewer products were returned than the pageSize
              if (response.length < this.pageSize) {
                this.showMoreButton = false;
              }
            },
            (error: any) => {
              console.error('Error fetching all products', error);
            }
          );
        } else {
          // Filter products by the selected category
          this.conomeService.getProductsByCategoryId(companyId, categoryId, this.page, this.pageSize).subscribe(
            (response: any) => {
              if (this.page === 1) {
                this.filteredProducts = response;
              } else {
                this.filteredProducts = [...this.filteredProducts, ...response];
              }
              this.updateFilteredCount();
              if (response.length < this.pageSize) {
                this.showMoreButton = false;
              }
            },
            (error: any) => {
              console.error('Error fetching products', error);
            }
          );
        }
    
        this.previousCategory = this.selectedCategory;
        this.sortProducts();
      }
    searchProducts() {
        const productsToFilter = this.selectedCategory === -1 ? this.allProductsFiltered : this.filteredProducts;
    
        if (this.searchText && this.searchText.trim() !== '') {
            const searchKey = 'name' + this.currentLanguage.toUpperCase();
            this.allProductsFiltered = productsToFilter.filter(product => {
                const productName = product[searchKey];
                return productName ? productName.toUpperCase().includes(this.searchText.toUpperCase()) : false;
            });
        } else {
            this.filterProductsByCategory();
        }
        this.updateFilteredCount();
        this.loadMore(true);
    }
    
    updateFilteredCount() {
        this.totalFilteredProductsCount = this.filteredProducts.length;
    }

    getAllProducts(){
        const companyId = Number(this.cookieService.get('companyID'));
        this.conomeService.getAllProducts(companyId, this.page, this.pageSize).subscribe({
            next: (res) => {
                this.productsFromConome = res;
                this.filteredProducts = this.productsFromConome;
                this.conomeService.categories$.subscribe(categories => {
                    this.categoriesFromConome = categories;
                });
            },
            error: (err) => {
                console.error(err);
            }
        })
    }

        showMore(): void {
            this.page++;
            this.filterProductsByCategory();
          }
        
          loadMore(reset: boolean = false) {
            if (reset) {
              this.filteredProducts = [];
            }
            const start = (this.page - 1) * this.pageSize;
            const end = this.page * this.pageSize;
            const newProducts = this.allProductsFiltered;
            this.filteredProducts = [...this.filteredProducts, ...newProducts];
            this.updateProgressBar();
          }


  updateProgressBar(): void {
    const loadedPercentage = (this.filteredProducts.length / this.totalFilteredProductsCount) * 100;
    this.barFilled = loadedPercentage;
  }


    @HostListener('window:resize')
    onResize() {
        this.checkDeviceIfOnMobile()
    }

    checkDeviceIfOnMobile() {
        this.isMobile = window.innerWidth < 1100
    }

    currentProductDetail: any;
    productModal(productDetailModal: any, currentProduct: any) {
        this.currentProduct = currentProduct
        this.currentProductImages = currentProduct.image
        this.currentProductDetail = currentProduct.productDetails[0];
        this.modalService.open(productDetailModal, { size: 'xl', centered: true });
        const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);

        if (availableDetail) {
            this.currentProductDetail = availableDetail;
        } else {
          return;
        }

    }

    isModalOpen(): boolean {
    return this.modalService.hasOpenModals();
    }

    selectProductDetail(detail: any) {
        if (detail.qt > 0) {
            this.currentProductDetail = detail;
        } else {
            const nextAvailableDetail = this.currentProduct.productDetails.find((d: any) => d.qt > 0);
            if (nextAvailableDetail) {
                this.currentProductDetail = nextAvailableDetail;
            } else {
               return;
            }
        }
    }

    shareOnFacebook(product: any) {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(shareUrl, '_blank');
    }


    shareOnWhatsApp(product: any) {
        
        const checkOutThisPost = this.translateService.instant('product-details.CHECK-OUT-THE-PRODUCT') 
        const message = `${checkOutThisPost} ${this.currentLanguage == 'en' ? this.currentProduct.name_en : this.currentLanguage == 'sq' ? this.currentProduct.name_sq : this.currentProduct.name_mk}`;
        const url = encodeURIComponent(window.location.href);
        const whatsappLink = `https://api.whatsapp.com://send?text=${message} ${url}`;
        window.location.href = whatsappLink;
    }

    shareOnTwitter(product: any) {
        const postTitle = this.currentLanguage === 'en' ? this.currentProduct.name_en :
            this.currentLanguage === 'sq' ? this.currentProduct.name_sq :
                this.currentProduct.name_mk;
    
        const tweetText = encodeURIComponent(postTitle);
        const tweetUrl = encodeURIComponent(window.location.href);
        
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;
        
        window.open(twitterShareUrl, '_blank', 'width=600,height=400');
    }

    filterImg(e: any, image: any) {
        document.querySelectorAll('.product-gallery-thumblist a').forEach(element => {
            element.classList.remove('active')
        });
        const img: HTMLImageElement = (document.querySelector('#first img') as HTMLImageElement);
        img.src = image;
        e.target.closest('a').classList.add('active');
    }

    searchProduct() {
        this.indexPage = 0;
        this.filteredProducts = this.productsFromConome.filter((p: any) =>
            (p["name" + this.currentLanguage].toUpperCase().includes(this.searchText.toUpperCase()))
        );

        this.filterProductsByDate();
        this.filterByPrice();
        this.filterByCategory();
        this.products = this.allProductsFiltered.slice((this.indexPage) * this.itemsPerPage, (this.indexPage + 1) * this.itemsPerPage)
        // Find the most expensive price
        const mostExpensiveProduct = this.allProductsFiltered.reduce((maxProduct, currentProduct) => {
            return currentProduct.price > maxProduct.price ? currentProduct : maxProduct;
        }, this.allProductsFiltered[0]);

        this.maxPriceValue = mostExpensiveProduct.price;
    }

    filterByPrice() {
        const maxPrice = this.filter.prices.max
        const minPrice = this.filter.prices.min

        // The lines bellow check the prices and filter the products accordingly.
        this.allProductsFiltered = this.allProductsFiltered.filter(
            (p: { price: number }) => {
                if (minPrice === 0 && maxPrice === 0) return true;
                if (minPrice > 0 && maxPrice == 0) return minPrice <= p.price;
                if (minPrice === 0 && maxPrice > 0) return maxPrice >= p.price;
                if (minPrice > 0 && maxPrice > 0) return minPrice <= p.price && p.price <= maxPrice;
                return minPrice <= p.price && p.price <= maxPrice;
            }
        );
        // These lines below check if values of price inputs are null or 0 and resets the products as before.
        if ((maxPrice === null && minPrice === null) || (maxPrice == 0 && minPrice == 0) || (maxPrice == null && minPrice == 0) || (maxPrice == 0 && minPrice == null)) {
            this.products = this.allProductsFiltered.slice((this.indexPage) * this.itemsPerPage, (this.indexPage + 1) * this.itemsPerPage)
        }
    }

    addToCart(product: any) {
        this.currentProduct = product;

        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }

        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
        this.showSuccessMessage = true;
        this.cartService.addToCart(product, this.currentProductDetail, this.userId)
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);

    }

    get currentLanguage() {
        return this.translateService.currentLang

    }

    clearFilter() {
        this.selectedCategory = -1;
        this.sharedService.setCategoryId(this.selectedCategory)
        this.page = 1;
        this.filterPriceForm.reset();
        this.filterProductsByCategory();
    }

    columnNameByLanguage(category: any) {
        if (this.currentLanguage === 'sq') { }
        const lang = "name" + this.currentLanguage.toUpperCase();
        return category[lang];
    }
    //get products categories
    get categories() {
        return this.categoriesService.categories;
    }

    sortProducts() {
        if (this.isRecentlyAdded) {
            this.filteredProducts.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
        } else {
            this.filteredProducts.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
        }
    }
    //filtering products by latest added
    filterByRecentlyAdded() {
        this.isRecentlyAdded = true;
        this.sortProducts();
    }

    // Method to set sorting by oldest
    filterByOldest() {
        this.isRecentlyAdded = false;
        this.sortProducts();
    }
    //sorting products by the date
    filterProductsByDate() {
        this.filteredProducts = this.filteredProducts.sort((a, b) => {
            const dt1 = Date.parse(a.created);
            const dt2 = Date.parse(b.created);
            return this.isRecentlyAdded ? dt2 - dt1 : dt1 - dt2;

        });
    }
    //filtering products by the category
    filterByCategory() {
        this.allProductsFiltered = this.allProductsFiltered.filter(p => (this.selectedCategory === -1 ? p : p.categoryId === this.selectedCategory))
        this.products = this.allProductsFiltered.slice((this.indexPage) * this.itemsPerPage, (this.indexPage + 1) * this.itemsPerPage)
    }

    // To detect when the value at minimum is changed, is used to hide the 'x' button
    onMinPriceChange(newValue: string) {
        const numericValue = this.parseInputValue(newValue);
        this.filter.prices.min = numericValue;
    }
    // To detect when the value at maximum is changed, is used to hide the 'x' button
    onMaxPriceChange(newValue: string) {
        const numericValue = this.parseInputValue(newValue);
        this.filter.prices.max = numericValue;
    }
    //this function takes a string value as input and attempts to convert it into a number using parseInt. If the conversion is successful, the numeric value is returned; otherwise, it returns 0.
    private parseInputValue(value: string): number {
        const parsedValue = parseInt(value);
        return isNaN(parsedValue) ? 0 : parsedValue;
    }
    //is used to stop writing Letter or symbol
    restrictInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        const newValue = input.value.replace(/\D/g, ''); // Remove non-numeric characters
        input.value = newValue;
    }
    // with this method we can press enter
    handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.restrictInput(event);
        }
    }

    //is used for banner data
    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.PRODUCTS").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/products' },
        ];
    }

    //shows modal if the payement was success or fail
    showSuccessOrFailMessageAndNavigate(message: string, isSucess: boolean) {
        Swal.fire({
            title: '',
            text: message,
            icon: isSucess ? 'success' : 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            confirmButtonColor: '#fe696a',
            customClass: {
                confirmButton: 'btn-primary'
            }
        });
    }

    addToWishList(product: any) {

        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }
        let productsArray: any[] = [];
        this.showSuccessMessage = false;
        this.showWarningMessage = false;
        const productDetailId = this.currentProductDetail.id;
    
        if (this.userId === '') {
            const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            if (storedWishlist) {
                productsArray = JSON.parse(storedWishlist);
                if (!Array.isArray(productsArray)) {
                    productsArray = [];
                }
            }
            const productExists = productsArray.some(
                item => item.productId === product.id && item.productDetailId === productDetailId
            );
    
            if (productExists) {
                this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
                this.showWarningMessage = true;
            } else {
                productsArray.push({ productId: product.id, productDetailId: productDetailId, image: product.image });
                this.cookieService.set(LOCAL_STORAGE.WISH_LIST, JSON.stringify(productsArray), { path: '/' });
                this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                this.showSuccessMessage = true;
            }
        } else {
            const wishlistData = {
                productId: product.id,
                productDetailId: productDetailId,
                userId: this.userId,
                image: product.image
            };
    
            const productExists = this.wishListData.some(
                (item: any) => item.productId === product.id && item.productDetailId === productDetailId
            );
            if (productExists) {
                this.successMessage = this.translateService.instant("home.PRODUCT-EXIST-TO-WISHLIST");
                this.showWarningMessage = true;
            } else {
                this.wishListData = [...this.wishListData, wishlistData];
                productsArray.push(wishlistData);
                this.cmsService.setWishList(wishlistData).subscribe(
                    response => {
                    },
                );
                this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                this.showSuccessMessage = true;
            }
        }
        setTimeout(() => {
            this.showSuccessMessage = false;
            this.showWarningMessage = false;
        }, 2000);
    }
    
    getFromWishList() {
        if (this.userId === '') {
            const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            this.wishListData = wishListCmsData ? JSON.parse(wishListCmsData) : []
            // this.fetchConomeData();

        }
        else {
            this.cmsService.getWishList(this.userId).subscribe(res => {
                this.wishListData = res.data
                    .map((item: any) => {
                        return {
                            productId: item.attributes.productId,
                            productDetailId: item.attributes.productDetailId,
                            userId: item.attributes.userId
                        };
                    });
            });
        }
    }

    isInWishList(item: any): boolean {
        return this.wishListData?.some((wish: any) => wish.productId === item.id);
    }

    truncateText(text: string, limit: number = 10): string {
        if (!text) return '';
        return text.length > limit ? text.substring(0, limit) : text;
      }

    getCategoryIdFromHome(){
        const categoryId = this.sharedService.getCategoryId()
        if(categoryId){
            this.selectedCategory = categoryId;
            this.filterProductsByCategory()
        }
    }

    productDetails(id: any) {
        this.router.navigate(['product-details', id])
    }

}
